<template>
  <div class="loginContBox" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="logoBox">
      <div class="logo">
        <!-- class="mr-3"  -->
        <img src="@/assets/image/logo.png" height="35" />
      </div>
      <div class="name">
        {{ webTitleName }}
      </div>
    </div>
    <div class="VerticalMid w-100">
      <img src="@/assets/image/Login/img_login.jpg" style="height: 500px" alt="" />
    </div>
    <div class="loginBody">
      <!-- 输入框在右侧时，需要空DIV对它挤压 -->
      <div style="flex: 1"></div>
      <div class="loginControl">
        <div class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-circle-left"></use>
          </svg>
          <span>账号登录</span>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-circle-right"></use>
          </svg>
        </div>
        <div class="inputItem">
          <el-input placeholder="登录账号" v-model="txtLoginName" class="txtInput">
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </div>
        <div class="inputItem">
          <el-input show-password placeholder="登录密码" v-model="txtLoginPwd" class="txtInput" @keyup.enter.native="onWebLogin()">
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </div>
        <div class="spErrCont">{{ tipErr }}</div>
        <div class="loginBtn">
          <el-button type="primary" size="medium" class="btnLogin" @click="onWebLogin()">立即登录</el-button>
        </div>
        <!-- <div class="loginLink">
          <a href="#" class="alogin">申请账号</a>
        </div> -->
      </div>
    </div>
    <div class="footer">
      {{ webCopyright }}
      <a href="https://beian.miit.gov.cn/" target="_blank">
        {{ webICP }}
      </a>
    </div>
  </div>
</template>
<script>
import versionTood from '@/plugins/versionUpdate.js';
export default {
  data() {
    return {
      fullscreenLoading: false,
      // 登录账号
      txtLoginName: '',
      // 登录密码
      txtLoginPwd: '',
      // 验证提示语
      tipErr: '',
      // 备案号
      webICP: webICP(),
      // 版权
      webCopyright: webCopyright(),
      // 系统名称
      webTitleName: webTitleName(),
      optionUrl: null,

      bgStyle: ''
    };
  },
  created() {
    let that = this;
    // 判读是否是移动端
    if (that.isMobile()) {
      window.location.href = that.$WebUrl() + '/mobile/';
    } else {
      let path = decodeURIComponent(that.$route.query.url);
      if (!regNull(path)) {
        that.optionUrl = path.indexOf('login?url=') < 0 ? path : null;
      }
    }
  },
  mounted() {
    let that = this;
    that.onGetBgStyle();
    // 浏览器大小变化事件
    window.onresize = () => {
      return (() => {
        that.$nextTick(() => {
          that.onGetBgStyle();
        });
      })();
    };
  },
  methods: {
    /**
     * @description 计算图片大小
     */
    onGetBgStyle() {
      let that = this;
      if ($(document).width() / $(document).height() > 1.7) {
        that.bgStyle = 'width:110%';
      } else {
        that.bgStyle = 'height:100%';
      }
    },
    /**
     * @description 用于判断是否为移动端
     */
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    onWebLogin() {
      // 验证登录用户名和密码是否为空
      let that = this;
      if (regNull(that.txtLoginName)) {
        that.tipErr = '用户名不能为空';
        return false;
      }

      if (regNull(that.txtLoginPwd)) {
        that.tipErr = '密码不能为空';
        return false;
      }

      // MD5 加密
      var password = this.$md5(that.txtLoginPwd);
      //赋值
      let data = {
        username: that.txtLoginName,
        password: password
      };
      that.fullscreenLoading = true;
      that.tipErr = '';

      ajaxLogin(that, '/api/auth/token', data, function (res) {
        if (res.data.data.success != false) {
          that.fullscreenLoading = false;

          // 用于记录登录的缓存时间
          that.$SaveKey(new Date(), 'tokenTime');
          // 保存登录凭证
          that.$SaveKey(res.data.data.token);

          // 判断角色
          if (res.data.data.token.userType == 1) {
            // 教师登录
            // 获取活动类型树形列表
            ajaxGet(that, '/api/admin/ActivityType/treelist', null, function (resType) {
              if (resType.length > 0) {
                that.$SaveKey(resType, 'activityTypes');
              }
              // 获取用户信息
              ajaxGet(that, '/api/admin/sysuser/currentuser', null, function (resUser) {
                // 判断是否有角色
                if (regNullArray(resUser.userRoles)) {
                  that.$RemoveKey();
                  warning(that, '该用户没有任何角色，无法登录，请联系管理员');
                  return false;
                } else {
                  that.$SaveKey(resUser, 'currentUserData');
                  let tempRole = resUser.userRoles.find((item) => item.isAdviser == true);

                  if (regNull(tempRole)) {
                    // 判断是否有管理员角色
                    let tempUser = resUser.userRoles.find((item) => item.name.indexOf('管理员') != -1);
                    if (regNull(tempUser)) {
                      // 没有管理员角色
                      that.onRoleInfo(resUser, resUser.userRoles, 0);
                    } else {
                      // 有管理员角色
                      that.onRoleInfo(resUser, [tempUser], 0);
                    }
                  } else {
                    let roleList = resUser.userRoles.filter((item) => item.isAdviser != true);
                    roleList.unshift(tempRole); // 在最前面添加移除的元素
                    that.onRoleInfo(resUser, roleList, 0);
                  }
                }
              });
            });
          } else {
            // 学生
            // 获取学生信息
            ajaxGet(that, '/api/student/student', null, function (resStudent) {
              if (!regNull(resStudent)) {
                // 记录学生的学号和姓名
                that.$SaveKey(resStudent, that.$cacheStudent);
                that.$SaveKey(resStudent.studentNo, 'StudentNo');

                var path = decodeURIComponent(that.$route.query.url);
                if (path != null && typeof path != 'undefined' && path != 'undefined' && path != '') {
                  var paths = path.toLowerCase().split('/login?url=');
                  var url = paths[paths.length - 1];
                  if (url != null && typeof url != 'undefined' && url != 'undefined' && url != '') {
                    that.$router.replace(url);
                  } else {
                    that.$router.replace('/student/SelfReport/index');
                  }
                } else {
                  that.$router.replace('/student/SelfReport/index');
                }
              } else {
                that.$RemoveKey();
              }
            });
          }
        } else {
          that.fullscreenLoading = false;
          that.tipErr = res.data.data.message;
        }
      });
    },
    /**
     * @description 多角色时可循环登录
     * @param {Object} objUser
     * @param {Object} objItem
     * @param {Object} objCount
     */
    onRoleInfo(objUser, objItem, objCount) {
      let that = this;
      let tpActionApi = '/api/admin/sysaction/byroleid?roleId=' + objItem[objCount].roleId;
      // 获取菜单权限
      ajaxGet(that, tpActionApi, null, function (resAction) {
        if (resAction.success == true) {
          // 判断是否有权限
          if (regNullArray(resAction.data.actions)) {
            // 多角色时，当是最后角色时，则提示无权限
            if (objCount < objItem.length - 1) {
              that.onRoleInfo(objUser, objItem, objCount + 1);
            } else {
              that.$RemoveKey();
              warning(that, '当前用户的角色没有任何权限，无法登录，请联系管理员');
              return false;
            }
          }

          //获取班主任是当前用户的班级
          ajaxGet(that, '/api/admin/class/userclass', null, function (resManageClass) {
            // 如果是班主任，又没有管理的班级，那就要重新循环角色
            if (objItem[objCount].isAdviser && regNullArray(resManageClass)) {
              if (objCount < objItem.length - 1) {
                that.onRoleInfo(objUser, objItem, objCount + 1);
              } else {
                that.$RemoveKey();
                warning(that, '当前用户班主任无管理班级，无法登录，请联系管理员');
                return false;
              }
            }

            //当前用户所有班级范围
            ajaxGet(that, '/api/admin/sysuser/userfilter', null, function (resAllClass) {
              // 当角色名不是管理员，且不是admin时要判断当前角色有没有学生范围
              // if (objUser.name != '管理员' && objUser.userName != 'admin') {
              //   if (regNullArray(resManageClass) && regNullArray(resAllClass)) {
              //     warning(that, "当前用户没有学生范围，无法登录，请联系管理员");
              //     return false;
              //   }
              // }

              // 用于缓存教师信息
              let tempTeacher = {
                userId: objUser.userId,
                isTeacher: objItem[objCount].isAdviser, // 是否是班主任
                manageClass: regNullArray(resManageClass) ? [] : resManageClass, // 如果是班主任这里记录所管辖的班级
                allClass: regNullArray(resAllClass) ? [] : resAllClass, // 学生范围
                userName: resAction.data.userName,
                name: resAction.data.name,
                userRoles: objUser.userRoles
              };
              // 缓存角色信息
              that.$SaveKey(objItem[objCount], 'nowRole');
              // 记录权限信息
              that.$SaveKey(resAction.data.actions, 'actions');
              // 记录菜单信息
              that.$SaveKey(resAction.data.menus, 'menus');
              // 记录教师的数据
              that.$SaveKey(tempTeacher, that.$cacheTeacher);
              // 记录教师的用户名和姓名
              that.$SaveKey(
                {
                  sysName: resAction.data.userName,
                  name: resAction.data.name
                },
                that.$cacheTeacherName
              );

              // 记录教师的姓名（临时）
              that.$SaveKey(resAction.data.name, 'Name');

              // 用于判断登录角色
              that.$SaveKey(true, 'isManager');

              // 判断是否有其他页面跳转过来的
              if (!regNullArray(resAction.data.menus)) {
                that.fullscreenLoading = true;
                setTimeout(function () {
                  that.fullscreenLoading = false;
                  if (!regNull(that.optionUrl) && that.optionUrl.toLowerCase().indexOf('/admin/') != -1) {
                    that.$router.replace(that.optionUrl);
                  } else {
                    var it = resAction.data.menus.find((x) => x.isLink == true);
                    if (regNull(it)) {
                      it = resAction.data.menus[0].children.find((x) => x.isLink == true);
                    }
                    that.$router.replace(it.url);
                  }
                  return false;
                }, 200);
              }
            });
          });
        } else {
          that.$RemoveKey();
        }
      });
    }
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  }
};
</script>
<style scoped="scoped" lang="less">
.loginBody {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  // background: url(../assets/image/img_login.jpg) no-repeat;
  // background-attachment: fixed;
  // background-size: auto 125%;
  // background-position: center;
}

.loginImg {
  height: 37.5rem;
  margin-right: 5%;
}

.loginControl {
  background-color: #ffffff;
  width: 25.625rem;
  height: 25rem;
  border-radius: 0.3125rem;
  margin-right: 20%;

  .title {
    margin-top: 1.875rem;
    color: #406ad3;
    text-align: center;
    padding-top: 0.9375rem;
    margin-bottom: 3rem;

    span {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      font-size: 1.25rem;
      color: #000000;
    }
  }

  .inputItem {
    text-align: center;
    margin-left: 4.0625rem;
    margin-right: 4.0625rem;
    margin-bottom: 0.9375rem;
  }

  .loginBtn {
    text-align: center;
    margin-left: 4.0625rem;
    margin-right: 4.0625rem;
  }

  .btnLogin {
    font-size: 0.875rem;
    width: 100%;
    background-color: #406ad3;
    margin-bottom: 0.3125rem;
    margin-top: 0.9375rem;
  }

  .loginLink {
    margin-left: 4.0625rem;
    margin-right: 4.0625rem;

    .alogin {
      font-size: 0.75rem;
      color: #767676;
    }
  }
}

.spErrCont {
  height: 1.25rem;
  color: #fa3534;
  font-size: 0.75rem;
  margin-left: 4.0625rem;
  margin-right: 4.0625rem;
}

.loginContBox {
  position: relative;
  min-width: 800px;
  min-height: 700px;
  display: flex;
  height: 100%;
  color: #ffffff;
  background-color: #416ad4;

  .divBgImg {
    text-align: ;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
  }
}

.logoBox {
  position: fixed;
  top: 1.875rem;
  left: 1.875rem;
  display: flex;

  .logo {
    height: 2.5rem;
    /*40*/
    border-right: 0.0625rem solid #ffffff;
    padding-right: 0.9375rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .name {
    padding-left: 0.9375rem;
    font-size: 1.75rem;
    margin-top: -0.0625rem;
    color: #ffffff;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2.5rem;
  background-color: #395fbf;
  text-align: center;
  font-size: 0.75rem;
  line-height: 2.5rem;

  a {
    color: #ffffff;
    font-size: 0.75rem;
    margin-left: 0.3125rem;
  }
}
</style>
<style scoped="scoped">
/*当屏幕尺寸小于1600px时，应用下面的CSS样式*/
@media screen and (max-width: 1650px) {
  .loginImg {
    height: 31.25rem;
    margin-right: 5%;
  }
}
</style>
