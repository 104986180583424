<template>
  <div class="bottom-right-table-3">
    <dv-border-box-12 class="studentRankType-body">
      <div class="divTabs" v-if="!regNullArray(optType)">
        <span v-for="(item, index) in optType" :key="index + Math.random()" :class="{ spAct: currentTabs == index }" @click="onTabs(index)">{{ item.configName }}</span>
      </div>
      <div class="divItemBox" v-if="!regNullArray(optType)">
        <el-carousel class="carousel-body" :autoplay="false" ref="carouselRef" arrow="never" indicator-position="none">
          <el-carousel-item v-for="(item, index) in optType" :key="index">
            <div class="item-body" v-if="!regNullArray(item.children)">
              <div class="divListItem" v-for="(list, indexTwo) in item.children.slice(0, 15)" :key="indexTwo + Math.random()">
                <div class="divIcon">
                  <span class="spRank" v-if="list.ranking == 1">
                    <img src="@/assets/image/icon/no_1.png" class="iconImg" />
                  </span>
                  <span class="spRank" v-else-if="list.ranking == 2">
                    <img src="@/assets/image/icon/no_2.png" class="iconImg" />
                  </span>
                  <span class="spRank" v-else-if="list.ranking == 3">
                    <img src="@/assets/image/icon/no_3.png" class="iconImg" />
                  </span>
                  <span class="spRankOther" v-else>
                    {{ list.ranking }}
                  </span>
                </div>
                <div class="divinfo">
                  <div class="divName">{{ list.studentName | flGetName }}</div>
                  <div class="divClass">{{ list.className }}</div>
                </div>
                <div class="divTotal">
                  <span>{{ list.score | flScore }}</span>
                  分
                </div>
              </div>
            </div>
            <div class="divNullData" v-else>
              <dv-loading>Loading...</dv-loading>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- <div class="divList" v-for="(item, index) in optData.slice(0, 3)" :key="index + Math.random()">
          <div class="divListItem rightB" v-for="(list, indexTwo) in item.data.slice(0, 5)" :key="indexTwo + Math.random()">
            <div class="divIcon">
              <span class="spRank" v-if="list.ranking == 1">
                <img src="@/assets/image/icon/no_1.png" class="iconImg" />
              </span>
              <span class="spRank" v-else-if="list.ranking == 2">
                <img src="@/assets/image/icon/no_2.png" class="iconImg" />
              </span>
              <span class="spRank" v-else-if="list.ranking == 3">
                <img src="@/assets/image/icon/no_3.png" class="iconImg" />
              </span>
              <span class="spRankOther" v-else>
                {{ list.ranking }}
              </span>
            </div>
            <div class="divinfo">
              <div class="divName">{{ list.studentName }}</div>
              <div class="divClass">{{ list.className }}</div>
            </div>
            <div class="divTotal">
              <span>{{ list.score | flScore }}</span>
              分
            </div>
          </div>
        </div> -->
      </div>
      <div class="divNullData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
export default {
  name: 'BottomRightTable3',
  data() {
    return {
      optType: [],
      currentTabs: 0,
      optData: [],
      optAllData: [],
      tabTtimer: null,
      iCount: 0
    };
  },
  mounted() {
    let that = this;
    that.tabTtimer = setInterval(() => {
      if (that.iCount >= that.optType.length - 1) {
        that.iCount = 0;
        that.onTabs(that.iCount);
      } else {
        that.iCount += 1;
        that.onTabs(that.iCount);
      }
    }, 10000);
  },
  methods: {
    /**
     * @description 加载数据
     * @param {Object} objData
     */
    onCreateData(objData) {
      let that = this;
      if (!regNullArray(objData)) {
        that.optType = objData.map((item, index) => ({
          configName: item.configName,
          configId: index,
          children: []
        }));

        let tempData = [];
        objData.forEach((item, index) => {
          if (!regNullArray(item.data)) {
            item.data.forEach((list, indexTwo) => {
              if (!regNullArray(list.data)) {
                list.data.forEach((listTwo) => {
                  listTwo.configName = item.configName;
                  tempData.push(listTwo);
                });
              }
            });
          }
        });

        setTimeout(() => {
          that.optType.forEach((item) => {
            item.children = tempData.filter((fl) => fl.configName == item.configName);
          });
          return false;
        }, 500);
      }
    },

    onTabs(objIndex) {
      let that = this;
      that.currentTabs = objIndex;
      setTimeout(function () {
        that.$refs.carouselRef.setActiveItem(objIndex);
        return false;
      }, 100);
    },
    onStopTime() {
      clearInterval(this.tabTtimer);
    }
  },
  filters: {
    /**
     * @description 过滤处理分数
     * @param {Object} obj
     */
    flScore(obj) {
      return obj != 0 ? setTwoDecimal(obj, 2) : '0.00';
    },
    flGetName(objItem) {
      if (regNull(objItem)) {
        return '***';
      } else {
        if (objItem.length <= 2) {
          return objItem[0] + '*';
        } else {
          let tpFirst = objItem[0];
          let tpLst = objItem[objItem.length - 1];
          let minLength = objItem.length - 2;
          let char = [...new Array(minLength).fill('*')];
          return tpFirst + char.join('') + tpLst;
        }
      }
    }
  }
};
</script>

<style lang="less">
.studentRankType-body {
  padding: 10px;

  .divNullData {
    padding-top: 20%;
  }

  .rightB {
    border-right: 1px dotted #2e6099;
  }

  .divItemBox {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
  }
  .item-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .divListItem {
      width: 33%;
      display: flex;
      padding: 15px 10px;

      .divIcon {
        height: 40px;
        width: 40px;

        .spRank {
          .iconImg {
            margin-top: 4px;
            width: 30px;
          }
        }

        .spRankOther {
          margin-top: 4px;
          background: url(../../assets/image/icon/no_other.png) no-repeat;
          display: block;
          height: 36px;
          background-size: 100%;
          width: 30px;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
        }
      }

      .divinfo {
        flex: 1;
        margin-left: 10px;
        .divName {
          font-size: 16px;
        }
        .divClass {
          font-size: 12px;
        }
      }

      .divTotal {
        padding-right: 10px;
        padding-top: 5px;
        span {
          font-size: 26px;
          color: #00c0ff;
          font-weight: bolder;
        }
      }
    }
  }
  .divTabs {
    display: flex;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      font-size: 18px;
      width: 100%;
      text-align: center;
      color: #95cef9;
      cursor: pointer;
    }

    .spAct {
      color: #fff;
      font-weight: bolder;
    }
  }
  .carousel-body {
    padding-top: 10px;
    height: 400px;
    .el-carousel__container {
      height: 380px;
    }
  }
}

.bottom-right-table-3 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1601px) {
  .studentRankType-body {
    padding: 10px;
    .item-body {
      .divListItem {
        width: 33%;
        padding: 10px 10px;

        .divIcon {
          height: 40px;
          width: 40px;

          .spRank {
            .iconImg {
              margin-top: 4px;
              width: 30px;
            }
          }

          .spRankOther {
            margin-top: 4px;
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divinfo {
          margin-left: 10px;
          .divName {
            font-size: 16px;
          }
          .divClass {
            font-size: 12px;
          }
        }

        .divTotal {
          padding-right: 10px;
          padding-top: 5px;
          span {
            font-size: 18px;
          }
        }
      }
    }

    .divTabs {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      span {
        font-size: 14px;
      }
    }

    .carousel-body {
      padding-top: 10px;
      height: 330px;
      .el-carousel__container {
        height: 330px;
      }
    }
  }
}
@media screen and (max-width: 1441px) {
  .studentRankType-body {
    padding: 10px;
    .item-body {
      .divListItem {
        width: 33.3%;
        padding: 5px 5px;

        .divIcon {
          height: 40px;
          width: 40px;
          .spRank {
            .iconImg {
              margin-top: 4px;
              width: 30px;
            }
          }

          .spRankOther {
            margin-top: 4px;
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divinfo {
          margin-left: 10px;
          .divName {
            font-size: 14px;
          }
          .divClass {
            font-size: 12px;
          }
        }

        .divTotal {
          padding-right: 10px;
          padding-top: 5px;
          font-size: 14px;
          span {
            font-size: 16px;
          }
        }
      }
    }

    .divTabs {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      span {
        font-size: 14px;
      }
    }

    .carousel-body {
      padding-top: 10px;
      height: 290px;
      .el-carousel__container {
        height: 280px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .studentRankType-body {
    .item-body {
      .divListItem {
        .divIcon {
          .spRank {
            .iconImg {
              margin-top: -2px;
              width: 30px;
            }
          }

          .spRankOther {
            margin-top: -2px;
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divinfo {
          margin-left: 4px;
          .divName {
            font-size: 12px;
          }
          .divClass {
            font-size: 10px;
          }
        }

        .divTotal {
          padding-right: 10px;
          padding-top: 5px;
          font-size: 14px;
          span {
            font-size: 16px;
          }
        }
      }
    }

    .divTabs {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      span {
        font-size: 14px;
      }
    }

    .carousel-body {
      padding-top: 10px;
      height: 290px;
      .el-carousel__container {
        height: 280px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .studentRankType-body {
    padding: 10px;
    .item-body {
      .divListItem {
        width: 33.3%;
        padding: 7px 5px;

        .divIcon {
          height: 33.8px;
          width: 28.2px;
          .spRank {
            .iconImg {
              margin-top: -4px;
              width: 30px;
            }
          }

          .spRankOther {
            margin-top: -4px;
            height: 33.8px;
            width: 28.2px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divinfo {
          margin-left: 10px;
          .divName {
            font-size: 12px;
          }
          .divClass {
            font-size: 10px;
          }
        }

        .divTotal {
          padding-right: 10px;
          padding-top: 5px;
          font-size: 10px;
          span {
            font-size: 12px;
          }
        }
      }
    }

    .divTabs {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      span {
        font-size: 14px;
      }
    }

    .carousel-body {
      padding-top: 10px;
      height: 260px;
      .el-carousel__container {
        height: 250px;
      }
    }
  }
}
</style>
