<template>
  <div id="top-header">
    <dv-border-box-2 class="divSemesterBox" v-if="!regNull(comSemesterName)">
      <div class="dvSemster VerticalMid">
        {{ comSemesterName }}
      </div>
    </dv-border-box-2>

    <dv-border-box-2 class="divDateTimeBox">
      <div class="dvDateTime VerticalMid">
        {{ nowDateTime }}
      </div>
    </dv-border-box-2>

    <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5 class="header-center-decoration" :dur="3" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">学生综合评价智能驾驶舱</div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  props: {
    comSemesterName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nowDateTime: ''
    };
  },
  mounted() {
    let that = this;
    that.timer = setInterval(() => {
      that.onNowTimes();
    }, 1000);
  },
  methods: {
    /**
     * @description  获取当前时间
     */
    onNowTimes() {
      let that = this;
      let myDate = new Date();
      let wk = myDate.getDay();
      let yy = String(myDate.getFullYear());
      let mm = myDate.getMonth() + 1;
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours());
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes());
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds());
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      let week = weeks[wk];
      that.nowDateTime = yy + '年' + mm + '月' + dd + '日 ' + ' ' + week;
      // that.nowDateTime = yy + '年' + mm + '月' + dd + '日 ' + hou + ':' + min + ':' + sec + ' ' + week;
    }
  }
};
</script>

<style lang="less">
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .divSemesterBox {
    position: absolute;
    width: 250px;
    height: 50px;
    top: 40px;
    left: 220px;

    .dvSemster {
      width: 100%;
      height: 100%;
    }
  }

  .divDateTimeBox {
    position: absolute;
    top: 40px;
    right: 220px;
    width: 250px;
    height: 50px;

    .dvDateTime {
      width: 100%;
      height: 100%;
    }
  }

  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }

  .header-left-decoration,
  .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 26px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }
}

// 小于1600
@media screen and (max-width: 1601px) {
  //0.83
  #top-header {
    .divSemesterBox {
      width: 180px;
      height: 42px;
      .dvSemster {
        font-size: 0.83vw;
      }
    }
    .divDateTimeBox {
      width: 180px;
      height: 42px;

      .dvDateTime {
        font-size: 0.83vw;
      }
    }
    .header-center-decoration {
      height: 50px;
    }
    .header-left-decoration,
    .header-right-decoration {
      height: 50px;
    }
    .center-title {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 1440px) {
  #top-header {
    .divSemesterBox {
      width: 162px;
      height: 38px;
      .dvSemster {
        font-size: 0.75vw;
      }
    }
    .divDateTimeBox {
      width: 162px;
      height: 38px;

      .dvDateTime {
        font-size: 0.75vw;
      }
    }
    .header-center-decoration {
      height: 45px;
    }
    .header-left-decoration,
    .header-right-decoration {
      height: 45px;
    }
    .center-title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1366px) {
  #top-header {
    .divSemesterBox {
      width: 154px;
      height: 36px;
      .dvSemster {
        font-size: 0.71vw;
      }
    }
    .divDateTimeBox {
      width: 154px;
      height: 36px;

      .dvDateTime {
        font-size: 0.71vw;
      }
    }
    .header-center-decoration {
      height: 43px;
    }
    .header-left-decoration,
    .header-right-decoration {
      height: 43px;
    }
    .center-title {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1280px) {
  #top-header {
    .divSemesterBox {
      width: 145px;
      height: 34px;
      .dvSemster {
        font-size: 0.67vw;
      }
    }
    .divDateTimeBox {
      width: 145px;
      height: 34px;

      .dvDateTime {
        font-size: 0.67vw;
      }
    }
    .header-center-decoration {
      height: 40px;
    }
    .header-left-decoration,
    .header-right-decoration {
      height: 40px;
    }
    .center-title {
      font-size: 16px;
    }
  }
}
</style>
