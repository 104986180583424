<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          钉钉推送
        </h3>
      </div>
      <div class="card-body">
        <div class="tipBox">
          <div class="h5">钉钉模拟推送需知：</div>
          <div class="pb-2">1、点击推送，系统将会推送系统中所有班主任。</div>
          <div class="pb-2">2、为确保能收到通知，请确定钉钉手机号在系统平台注册用户信息，且为班主任（有班级关联)</div>
          <div class="pb-2">3、规定的日期内班级没有行为规范记录，系统则不推送。</div>
          <div class="pb-2 color-dark-red">4、系统推送消息后，钉钉可能会出现延迟接收的情况。</div>
        </div>

        <div class="item-box pt-5">
          <div class="btn-box">
            <el-button type="primary" icon="el-icon-s-promotion" @click="onMoralYesterday" class="btnPage" :disabled="disabledLoading">模拟每天06:30执行的消息推送</el-button>
          </div>
          <div class="tip-box">说明：模拟班主任每天收到前一天的日常行为规范的汇总。</div>
        </div>
        <div class="item-box">
          <div class="btn-box">
            <el-button type="primary" icon="el-icon-s-promotion" @click="onMoralToday" class="btnPage" :disabled="disabledLoading">模拟每天10:00执行的消息推送</el-button>
          </div>
          <div class="tip-box">说明：模拟班主任每天早上收到当天的日常行为规范的汇总</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
export default {
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      disabledLoading: false
    };
  },
  created() {
    let that = this;
  },

  methods: {
    onMoralToday() {
      let that = this;
      that.fullscreenLoading = true;
      that.disabledLoading = true;
      ajaxPost(that, '/api/schedule/moraltoday', null, function (res) {
        that.fullscreenLoading = false;
        that.disabledLoading = false;
      });
    },
    onMoralYesterday() {
      let that = this;
      that.fullscreenLoading = true;
      that.disabledLoading = true;
      ajaxPost(that, '/api/schedule/moral', null, function (res) {
        that.fullscreenLoading = false;
        that.disabledLoading = false;
      });
    }
  }
};
</script>

<style scoped="scoped" lang="less">
.tipBox {
  padding: 15px;
  border-radius: 5px;
  background-color: #fdf6eb;
  color: #e6a23d;
}

.item-box {
  margin-bottom: 100px;
  text-align: center;
  .btnPage {
    width: 300px;
  }
  .tip-box {
    padding-top: 15px;
    padding-left: 30px;
  }
}
</style>
