<template>
  <div class="bottom-right-table-1">
    <dv-border-box-12 class="ClassRank-body">
      <div class="divTitle">全校学生素养总分班级排行</div>
      <div v-if="!regNullArray(optData)">
        <el-carousel :autoplay="true" :interval="10000" indicator-position="none" class="carousel-class" arrow="never">
          <el-carousel-item v-for="(item, index) in optData" :key="index">
            <div class="divForBox">
              <div class="divItemTitle">
                {{ item.collegeName }}
              </div>
              <div v-if="!regNullArray(item.data)">
                <div class="divItem" v-for="(list, indexTwo) in item.data" :key="indexTwo">
                  <div class="divImg">
                    <span class="spRank" v-if="list.ranking == 1">
                      <img src="@/assets/image/icon/no_1.png" class="iconImg" />
                    </span>
                    <span class="spRank" v-else-if="list.ranking == 2">
                      <img src="@/assets/image/icon/no_2.png" class="iconImg" />
                    </span>
                    <span class="spRank" v-else-if="list.ranking == 3">
                      <img src="@/assets/image/icon/no_3.png" class="iconImg" />
                    </span>
                    <span class="spRankOther" v-else>
                      {{ list.ranking }}
                    </span>
                  </div>
                  <div class="divClass">{{ list.className }}</div>
                  <div class="divAvg">
                    <b>{{ list.average | flScore }}</b>
                    分/人
                  </div>
                  <div class="divTotal">
                    <span class="spNum">{{ list.score | flScore }}</span>
                    <span class="spFont">分</span>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- -->
      </div>
      <div class="divNoData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
export default {
  name: 'ClassRank',
  data() {
    return {
      optData: []
    };
  },
  methods: {
    /**
     * @description 加载数据
     * @param {Object} objData
     */
    onCreateData(objData) {
      let that = this;
      that.optData = JSON.parse(JSON.stringify(objData));
    }
  },
  filters: {
    /**
     * @description 过滤处理分数
     * @param {Object} obj
     */
    flScore(obj) {
      return obj != 0 ? setTwoDecimal(obj, 2) : '0.00';
    }
  }
};
</script>

<style lang="less">
.ClassRank-body {
  padding: 30px;
  .carousel-class {
    width: 100%;
  }
  .divNoData {
    text-align: center;
    padding-top: 30%;
  }
  .divTitle {
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .divForBox {
    padding-bottom: 6px;
    margin-bottom: 3px;

    &:last-child {
      border-bottom: none;
    }

    .divItemTitle {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .divItem {
      display: flex;
      font-size: 16px;
      padding-bottom: 15px;
      padding-top: 15px;
      width: 100%;

      .divImg {
        .spRank {
          .iconImg {
            width: 30px;
          }
        }

        .spRankOther {
          background: url(../../assets/image/icon/no_other.png) no-repeat;
          display: block;
          height: 36px;
          background-size: 100%;
          width: 30px;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          //<img src="@" class="iconImg">
        }
      }

      .divClass {
        padding-left: 15px;
        width: 150px;
        line-height: 32px;
      }

      .divAvg {
        flex: 1;
        line-height: 32px;

        b {
          margin-right: 4px;
        }
      }

      .divTotal {
        display: flex;
        line-height: 32px;

        .spNum {
          font-weight: bold;
          color: #00c0ff;
        }

        .spFont {
        }
      }
    }
  }
  .carousel-class {
    height: 880px;
    .el-carousel__container {
      height: 870px;
    }
  }
}

.bottom-right-table-1 {
  width: 100%;
  height: 100%;
  margin: 0 -5px;
}

@media screen and (max-width: 1601px) {
  .ClassRank-body {
    padding: 30px 30px;
    .divTitle {
      font-size: 26px;
      margin-bottom: 10px;
    }

    .divForBox {
      border-bottom: 1px solid #042153;
      padding-bottom: 6px;
      margin-bottom: 3px;

      .divItemTitle {
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .divItem {
        font-size: 16px;
        padding-bottom: 15px;
        padding-top: 10px;

        .divImg {
          .spRank {
            .iconImg {
              width: 30px;
            }
          }

          .spRankOther {
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divClass {
          padding-left: 15px;
          width: 150px;
          line-height: 32px;
        }

        .divAvg {
          line-height: 32px;

          b {
            margin-right: 4px;
          }
        }

        .divTotal {
          line-height: 32px;
        }
      }
    }
    .carousel-class {
      height: 700px;
      .el-carousel__container {
        height: 700px;
      }
    }
    .divNoData {
      padding-top: 30%;
    }
  }

  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
    margin: 0 -5px;
  }
}

@media screen and (max-width: 1441px) {
  .ClassRank-body {
    padding: 30px 30px;
    .divTitle {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .divForBox {
      border-bottom: 1px solid #042153;
      padding-bottom: 6px;
      margin-bottom: 3px;

      .divItemTitle {
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .divItem {
        font-size: 14px;
        padding-bottom: 8px;
        padding-top: 8px;

        .divImg {
          .spRank {
            .iconImg {
              width: 30px;
            }
          }

          .spRankOther {
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divClass {
          padding-left: 15px;
          width: 150px;
          line-height: 32px;
        }

        .divAvg {
          line-height: 32px;

          b {
            margin-right: 4px;
          }
        }

        .divTotal {
          line-height: 32px;
        }
      }
    }
    .carousel-class {
      height: 700px;
      .el-carousel__container {
        height: 700px;
      }
    }
    .divNoData {
      padding-top: 30%;
    }
  }

  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
    margin: 0 -5px;
  }
}

@media screen and (max-width: 1366px) {
  .ClassRank-body {
    padding: 15px 15px;
    .divTitle {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .divForBox {
      padding-bottom: 6px;
      margin-bottom: 3px;

      .divItemTitle {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 20px;
      }

      .divItem {
        font-size: 14px;
        padding-bottom: 6px;
        padding-top: 6px;

        .divImg {
          .spRank {
            .iconImg {
              width: 30px;
            }
          }

          .spRankOther {
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divClass {
          padding-left: 15px;
          width: 150px;
          line-height: 32px;
        }

        .divAvg {
          line-height: 32px;

          b {
            margin-right: 4px;
          }
        }

        .divTotal {
          line-height: 32px;
        }
      }
    }
    .carousel-class {
      height: 570px;
      .el-carousel__container {
        height: 570px;
      }
    }
    .divNoData {
      padding-top: 30%;
    }
  }

  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
    margin: 0 -5px;
  }
}

@media screen and (max-width: 1280px) {
  .ClassRank-body {
    padding: 15px 15px;
    .divTitle {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .divForBox {
      padding-bottom: 6px;
      margin-bottom: 3px;

      .divItemTitle {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 20px;
      }

      .divItem {
        font-size: 13px;
        padding-bottom: 5px;
        padding-top: 5px;

        .divImg {
          .spRank {
            .iconImg {
              width: 30px;
            }
          }

          .spRankOther {
            height: 36px;
            width: 30px;
            line-height: 32px;
            font-size: 12px;
          }
        }

        .divClass {
          padding-left: 15px;
          width: 150px;
          line-height: 32px;
        }

        .divAvg {
          line-height: 32px;

          b {
            margin-right: 4px;
          }
        }

        .divTotal {
          line-height: 32px;
        }
      }
    }
    .carousel-class {
      height: 540px;
      .el-carousel__container {
        height: 540px;
      }
    }
    .divNoData {
      padding-top: 30%;
    }
  }

  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
    margin: 0 -5px;
  }
}
</style>
