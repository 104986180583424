<!--后台网站框架-->
<template>
  <div class="tmpl_IndexPage webDefault" v-loading.fullscreen.lock="fullscreenLoading">
    <!--头部-->
    <comHeader :userName="userName" :isCollapse="isCollapse" @onSetCollapse="onSetCollapse"></comHeader>

    <div class="divMenuBox" id="menuBox" v-bind:style="isCollapse == true ? 'width:20%; max-width: 64px;' : ''">
      <div class="divLogo VerticalMid">
        <img src="@/assets/image/logodarkcolor.png" v-if="isCollapse == false" style="max-height: 40px" />
        <img src="@/assets/image/logo1.png" v-else />
      </div>
      <el-tooltip class="item" effect="dark" content="切换角色" placement="top" v-if="!regNullArray(optRole) && optRole.length > 1">
        <div class="divSwitchRole" @click="onOpenRole">
          <div class="divAvatar VerticalMid" v-show="!isCollapse">
            <svg class="icon" aria-hidden="true" v-if="nowRole.isAdviser">
              <use xlink:href="#fax-user-business"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-else>
              <use xlink:href="#fax-use-o"></use>
            </svg>
          </div>
          <div class="divName" v-show="!isCollapse">{{ nowRole.name }}</div>
          <div class="divIcon VerticalMid">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#fax-arrow-toggle-alt"></use>
            </svg>
          </div>
        </div>
      </el-tooltip>

      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :unique-opened="true"
        active-text-color="#409eff"
        @select="selectMenuItem"
      >
        <div v-for="(p, key) in optMenus" :key="key">
          <el-submenu v-if="p.children != null && p.children.length > 0" :index="p.actionId">
            <template slot="title">
              <svg class="menuIcon icon" aria-hidden="true">
                <use :xlink:href="'#fax-' + p.icon"></use>
              </svg>
              <span slot="title" v-if="isCollapse == false">{{ p.name }}</span>
            </template>
            <el-menu-item-group>
              <span slot="title" v-if="isCollapse == true" style="font-size: 16px">{{ p.name }}</span>
              <div v-for="(p1, key1) in p.children" :key="key1">
                <el-menu-item :index="p1.url" @click="onPage(p1.url)" v-if="p1.children == null || p1.children.length <= 0">
                  {{ p1.name }}
                </el-menu-item>
                <el-submenu :index="p1.actionId" v-else>
                  <span slot="title" style="font-size: 14px">{{ p1.name }}</span>
                  <el-menu-item @click="onPage(p2.url)" v-for="(p2, key2) in p1.children" :key="key2" :index="p2.url">{{ p2.name }}</el-menu-item>
                </el-submenu>
              </div>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item :index="p.url" v-else @click="onPage(p.url)">
            <!-- <i :class="p.icon"></i> -->
            <svg class="menuIcon icon" aria-hidden="true">
              <use :xlink:href="'#fax-' + p.icon"></use>
            </svg>
            <span slot="title">{{ p.name }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
    <div style="padding-top: 40px; margin-right: 20px" :style="isCollapse == false ? 'margin-left: 240px;min-width:784px;' : 'margin-left: 80px;min-width:944px;'">
      <router-view style="margin-top: 40px" v-wechat-title="$route.meta.title" v-if="ifRouterAlive" />
    </div>

    <div id="toTop" class="VerticalMid">
      <el-tooltip class="item" effect="dark" content="返回顶部" placement="top">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#fax-font-top-o"></use>
        </svg>
      </el-tooltip>
    </div>

    <el-dialog title="切换角色" :visible.sync="dialogRole" :before-close="handleCloseRole" class="dialogRole" :destroy-on-close="true">
      <div class="divDialogRoleBody" v-for="(item, index) in optRole" :key="index" @click="onSwitchRole(item)">
        <div class="divAvatar VerticalMid">
          <svg class="icon" aria-hidden="true" v-if="item.isAdviser">
            <use xlink:href="#fax-user-business"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else>
            <use xlink:href="#fax-use-o"></use>
          </svg>
        </div>
        <div class="divName">{{ item.name }}</div>
        <div class="divIcon VerticalMid">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-arrow-right-alt"></use>
          </svg>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import random from '@/assets/css/Admin.css';
import comHeader from '@/components/Admin/Header.vue';

export default {
  components: {
    comHeader
  },
  // 用于刷新子页面方法
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isCollapse: false,
      // 菜单数组
      optMenus: [],
      defaultActive: null,
      allMenu: [],
      userName: null,
      tokenInfo: null,
      menuData: [],
      ifRouterAlive: true,

      optRole: [],
      nowRole: {
        name: null,
        roleId: null,
        isAdviser: false
      },
      dialogRole: false,
      fullscreenLoading: false
    };
  },
  created() {
    let that = this;
    // 验证toKen
    IsToken(that);

    that.onGetMenu();
  },
  // computed: {
  //   routers() {
  //     return this.$route.path;
  //   }
  // },
  methods: {
    /**
     * @description 生成菜单
     */
    onGetMenu() {
      let that = this;
      // 获取用户菜单权限
      var menus = JSON.parse(that.$GetKey('menus'));
      that.nowRole = JSON.parse(that.$GetKey('nowRole'));
      let tempUser = JSON.parse(that.$GetKey(that.$cacheTeacher));

      that.optRole = regNull(tempUser) ? [] : tempUser.userRoles;

      // menus = menus.filter(item => item.code == "teacher_menu").children;

      // 用户名称
      that.userName = JSON.parse(that.$GetKey(that.$cacheTeacherName)).sysName + ' / ' + JSON.parse(that.$GetKey(that.$cacheTeacherName)).name;
      // 生成菜单
      RecursionMenu(that, menus);
      // 开始处理活动菜单页面
      var activityMenus = [];
      for (var it of menus) {
        if (it.url != null && typeof it.url != 'undefined' && it.url != '') {
          if (it.url.toLowerCase() != '/admin/activity/index/:type') {
            that.optMenus.push(it);
          } else {
            var item = JSON.parse(that.$GetKey('activityTypes'));
            if (item != null && item.length > 0) {
              var activityMenu = {
                url: '',
                name: it.name,
                title: it.name,
                code: it.code,
                children: [],
                actionId: it.actionId,
                icon: it.icon,
                isMenu: true
              };
              activityMenus.push(activityMenu);

              for (var activity of item) {
                var child = {
                  url: '/admin/activity/index/' + activity.id,
                  name: activity.name,
                  title: activity.name,
                  code: it.code,
                  children: null,
                  actionId: activity.id,
                  icon: it.icon,
                  parentId: it.actionId,
                  isMenu: true
                };
                activityMenus.push(child);
                activityMenu.children.push(child);
              }
              that.optMenus.push(activityMenu);
            }
          }
        } else {
          that.optMenus.push(it);
        }
      }
      var mentItem = JSON.parse(that.$GetKey('actions'));
      for (var it of mentItem) {
        if (it.url != null && typeof it.url != 'undefined' && it.url != '') {
          if (it.url.toLowerCase() != '/admin/activity/index/:type') {
            that.allMenu.push(it);
          } else {
            for (var it of activityMenus) {
              that.allMenu.push(it);
            }
          }
        }
      }

      var url = that.$route.matched[that.$route.matched.length - 1].path.toLowerCase();
      var exist = false;
      for (var i in that.allMenu) {
        if (
          url != '/admin/activity/index/:type' &&
          url != '/admin/activity/create/:type' &&
          url != '/admin/activity/edit/:type/:id' &&
          url != '/admin/activity/detail/:type/:id' &&
          url != '/admin/activity/range/:type/:id' &&
          url != '/admin/activity/confirm/:type/:id' &&
          url != '/admin/activity/roster/:type/:id' &&
          url != '/admin/activity/prize/:type/:id' &&
          url != '/admin/activity/prizecreate/:activityid' &&
          url != '/admin/activity/prizeedit/:activityid/:id' &&
          url != '/admin/activity/prizedetail/:activityid/:id'
        ) {
          var t = that.allMenu[i];
          if (t.url != null && typeof t.url != 'undefined' && t.url != '') {
            if (t.url.toLowerCase() == url) {
              GetDefaultActive(that, t.actionId);
            }
          }
        } else {
          var item = JSON.parse(that.$GetKey('activityTypes'));
          if (item.length > 0) {
            for (var it of item) {
              if (it.id == that.$route.params.type) {
                GetDefaultActive(that, that.$route.params.type);
              }
            }
          }
        }
      }
      isPower(that);
      if (that.defaultActive == null || that.defaultActive == '') {
        that.defaultActive = '1-1';
      }
    },
    /**
     * @description 切换角色
     * @param {Object} objItem
     */
    onSwitchRole(objItem) {
      let that = this;
      if (objItem.isAdviser == true) {
        let temp = JSON.parse(that.$GetKey(that.$cacheTeacher));
        if (regNull(temp.manageClass)) {
          warning(that, '当前用户班主任无管理班级，无法切换，请联系管理员');
          return false;
        }
      }
      ajaxGet(that, '/api/admin/sysaction/byroleid?roleId=' + objItem.roleId, null, function (resAction) {
        if (!regNull(resAction)) {
          // 判断是否有权限
          if (regNullArray(resAction.data.actions)) {
            warning(that, '当前用户的角色没有任何权限，无法切换，请联系管理员');
            return false;
          }

          that.optMenus = [];
          that.allMenu = [];
          that.menuData = [];

          let tempUser = onGetNowUser(that);
          tempUser.isTeacher = objItem.isAdviser;

          that.$SaveKey(objItem, 'nowRole');
          let tempName = {
            sysName: resAction.data.userName,
            name: resAction.data.name
          };
          that.$SaveKey(tempName, that.$cacheTeacherName);
          // 记录权限信息
          that.$SaveKey(resAction.data.actions, 'actions');
          // 记录菜单信息
          that.$SaveKey(resAction.data.menus, 'menus');
          // 记录教师的姓名（临时）
          that.$SaveKey(resAction.data.name, 'Name');

          that.dialogRole = false;
          that.fullscreenLoading = true;
          setTimeout(function () {
            that.$SaveKey(tempUser, that.$cacheTeacher);
            that.fullscreenLoading = false;
            if (that.$route.path.toLowerCase() != '/admin/preparations/index') {
              that.$router.replace('/Admin/Preparations/Index');
            } else {
              window.location.reload(true);
            }
            that.onGetMenu();
            return false;
          }, 1000);
        }
      });
    },
    onOpenRole() {
      let that = this;
      that.dialogRole = true;
    },
    /**
     * @description 关闭角色选择
     */
    handleCloseRole() {
      let that = this;
      that.dialogRole = false;
    },
    /**
     * @description 刷新子页面方法
     */
    reload() {
      let that = this;
      // that.ifRouterAlive=false;
      // that.$nextTick(()=>{
      //   that.ifRouterAlive=true;
      // })
    },
    /**
     * @description 菜单点击事件
     * @param {Object} objIndex
     * @param {Object} objIndexPath
     */
    selectMenuItem(url, objIndexPath) {
      let that = this;
    },
    onPage(url) {
      let that = this;
      if (url != null && typeof url != 'undefined' && url != '') {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        if (this.$route.path === url) {
          // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
          this.$router.replace({
            path: '/redirect',
            query: {
              path: encodeURI(url)
            }
          });
        } else {
          // 正常跳转
          this.$router.push({
            path: url
          });
        }
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    onSetCollapse(val) {
      let that = this;
      that.isCollapse = val;
    }
  },
  mounted() {
    let that = this;
    $(window).scroll(function () {
      if ($(that).scrollTop() != 0) {
        $('#toTop').fadeIn();
      } else {
        $('#toTop').fadeOut();
      }
    });

    $('#toTop').click(function () {
      $('body,html').animate(
        {
          scrollTop: 0
        },
        800
      );
    });

    // 实时更新token
    onRefreshToken(this);
  },
  watch: {
    $route: function () {
      let that = this;
      isPower(that);
    }
  }
};

/**
 * @description 生成菜单
 * @param {Object} that
 * @param {Object} menus
 */
function RecursionMenu(that, menus) {
  for (var it of menus) {
    if (it.code != null && (it.code.toLowerCase() == 'score_adviser' || it.code.toLowerCase() == 'dormitory_manage')) {
      if (it.children != null && typeof it.children != 'undefined' && it.children.length > 0) {
        it.url = it.children[0].url;
      } else {
        if (it.isLink != true) {
          var actions = JSON.parse(that.$GetKey('actions'));
          for (var action of actions) {
            if (action.parentId == it.actionId) {
              it.url = action.url;
              break;
            }
          }
        }
      }
    } else {
      if (it.children != null && typeof it.children != 'undefined' && it.children.length > 0) {
        RecursionMenu(that, it.children);
      }
    }
  }
}

/**
 * @description 判断页面是否有权限
 * @param {Object} that
 */
function isPower(that) {
  var url = that.$route.matched[that.$route.matched.length - 1].path.toLowerCase();
  var exist = false;
  for (var i in that.allMenu) {
    if (
      url != '/admin/activity/index/:type' &&
      url != '/admin/activity/create/:type' &&
      url != '/admin/activity/edit/:type/:id' &&
      url != '/admin/activity/detail/:type/:id' &&
      url != '/admin/activity/range/:type/:id' &&
      url != '/admin/activity/confirm/:type/:id' &&
      url != '/admin/activity/roster/:type/:id' &&
      url != '/admin/activity/prize/:type/:id' &&
      url != '/admin/activity/prizecreate/:activityid' &&
      url != '/admin/activity/prizeedit/:activityid/:id' &&
      url != '/admin/activity/prizedetail/:activityid/:id'
    ) {
      if (that.allMenu[i].url != null && typeof that.allMenu[i].url != 'undefined' && url == that.allMenu[i].url.toLowerCase()) {
        exist = true;
      }
    } else {
      if (url == '/admin/activity/index/:type' || url == '/admin/activity/create/:type' || url == '/admin/activity/edit/:type/:id' || url == '/admin/activity/detail/:type/:id') {
        var path = that.$router.history.current.fullPath.toLowerCase();
        if (that.allMenu[i].url != null && typeof that.allMenu[i].url != 'undefined' && path.indexOf(that.allMenu[i].url.toLowerCase()) >= 0) {
          exist = true;
        }
      } else {
        if (url == '/admin/activity/range/:type/:id' || url == '/admin/activity/confirm/:type/:id' || url == '/admin/activity/roster/:type/:id') {
          for (var it of that.allMenu) {
            if (it.code != null && it.code.toLowerCase() == 'activity_audit') {
              exist = true;
            }
          }
        } else if (
          url == '/admin/activity/prize/:type/:id' ||
          url == '/admin/activity/prizecreate/:activityid' ||
          url == '/admin/activity/prizeedit/:activityid/:id' ||
          url == '/admin/activity/prizedetail/:activityid/:id'
        ) {
          for (var it of that.allMenu) {
            if (it.code != null && it.code.toLowerCase() == 'activity_prize') {
              exist = true;
            }
          }
        }
      }
    }
  }
  if (
    exist == false &&
    // url.toLowerCase() != "/admin/home/index" &&
    // url.toLowerCase() != "/admin/home/home" &&
    // url.toLowerCase() != "/admin/home/default" &&

    // url.toLowerCase() != "/admin/preparations/index" &&
    // url.toLowerCase() != "/admin/static/deduction/create" &&
    // url.toLowerCase() != "/admin/static/deduction/reason" &&
    // url.toLowerCase() != "/admin/literacyreport/details" &&
    url.toLowerCase() != '/redirect' &&
    url.toLowerCase() != '/admin/action/index' && // 管理员菜单页面
    url.toLowerCase() != '/admin/action/student' && // 学生菜单页面
    // url.toLowerCase() != "/admin/prototype/index" &&
    url.toLowerCase() != '/admin/action/home' // 部署步骤页面
  ) {
    warning(that, '该页面权限您没有得到授权，请与管理员联系！');
    setTimeout(function () {
      that.$router.push({
        path: '/admin/Preparations/index'
      });
      return false;
    }, 1000);
  }
}

/**
 * @description 获得默认的菜单
 * @param {Object} that
 * @param {Object} id
 */
function GetDefaultActive(that, id) {
  for (var i in that.allMenu) {
    if (that.allMenu[i].actionId == id) {
      if (that.allMenu[i].isMenu == true) {
        that.defaultActive = id;
      } else {
        if (that.allMenu[i].parentId != null) {
          GetDefaultActive(that, that.allMenu[i].parentId);
        }
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.menuIcon {
  color: #909999;
  margin-right: 8px;
}

.divDialogRoleBody {
  display: flex;
  padding: 15px 20px;
  cursor: pointer;
  border: 1px solid #e4e7ed;
  margin-bottom: 15px;
  border-radius: 3px;

  .divAvatar {
    border: 1px solid #007bff;
    color: #007bff;
    height: 30px;
    width: 30px;
    border-radius: 3px;
  }

  .divName {
    flex: 1;
    height: 30px;
    padding-left: 15px;
    padding-top: 5px;
  }

  .divIcon {
    display: none;
  }

  &:hover {
    background: #edf5ff;

    .divAvatar {
      color: #ffffff;
      background-color: #007bff;
    }

    .divIcon {
      display: flex;
      height: 30px;
      width: 30px;
      color: #909999;
    }
  }
}

.divSwitchRole {
  display: flex;
  padding: 20px;
  background: #f2f6fc;
  cursor: pointer;

  .divAvatar {
    border: 1px solid #007bff;
    height: 30px;
    width: 30px;
    color: #ffffff;
    background-color: #007bff;
    border-radius: 3px;
  }

  .divName {
    flex: 1;
    height: 30px;
    padding-left: 15px;
    padding-top: 5px;
  }

  .divIcon {
    height: 30px;
    width: 30px;
    color: #909999;
  }
}

#toTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  display: none;
  padding: 2px 8px;
  cursor: pointer;
  background-color: #f7f7f7;
  color: #444444;
  border: 1px solid #dddddd;
  font-size: 26px;
  border-radius: 5px;
}

#toTop:hover {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
}

.box-card {
  margin-bottom: 16px;
}

.divMenuBox {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 13.75rem;
  /*220px*/
  /* min-height: 43.75rem; */
  /*700px*/
  background-color: #ffffff;
  z-index: 2;
  -moz-box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, 0.25), 0 0.625rem 0.625rem rgba(0, 0, 0, 0.22) !important;
  /*0 14px 28px | 0 10px 10px*/
  -webkit-box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, 0.25), 0 0.625rem 0.625rem rgba(0, 0, 0, 0.22) !important;
  /*0 14px 28px | 0 10px 10px*/
  box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, 0.25), 0 0.625rem 0.625rem rgba(0, 0, 0, 0.22) !important;
  /*0 14px 28px | 0 10px 10px*/
}

.divMenuBox .divLogo {
  height: 3.75rem;
}
</style>

<style lang="less">
.dialogRole {
  .el-dialog {
    width: 250px;
  }
}
</style>
