<template>
  <div class="bottom-right-table-1">
    <dv-border-box-12 class="evaluate-body">
      <div class="divTitle">学生动态</div>
      <div v-if="!regNullArray(optData)">
        <el-carousel class="item-body-left" direction="vertical" indicator-position="none" :autoplay="true" :interval="5000">
          <el-carousel-item v-for="(item, index) in optData" :key="index">
            <div class="divItemList" v-for="(list, indexTwo) in item.children" :key="indexTwo">
              <div class="divAva">
                <span class="VerticalMid">
                  {{ list.score > 0 ? '+' + list.score : list.score }}
                </span>
              </div>
              <div class="divInfo">
                <div>
                  <span class="spName">{{ list.studentName | flGetName }}</span>
                  <span class="spClass">【 {{ list.className }} 】</span>
                </div>
                <div class="divReason">
                  {{ list.reason }}
                </div>
              </div>
              <div class="divTime">
                {{ list.logDate | flLogDate }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- <div class="divItemList" v-for="(item, index) in optData.slice(0, 4)" :key="index">
          <div class="divAva">
            <span class="VerticalMid">
              {{ item.score }}
            </span>
          </div>
          <div class="divInfo">
            <div>
              <span class="spName">{{ item.studentName }}</span>
              <span class="spClass">【 {{ item.className }} 】</span>
            </div>
            <div>
              {{ item.reason }}
            </div>
          </div>
          <div class="divTime">
            {{ item.logDate | flLogDate }}
          </div>
        </div> -->
      </div>
      <div class="divNullData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
export default {
  name: 'BottomRightTable1',
  data() {
    return {
      optData: []
    };
  },
  methods: {
    /**
     * @description 加载数据
     * @param {Object} objData
     */
    onCreateData(objData) {
      let that = this;
      if (!regNullArray(objData)) {
        that.optData = [
          {
            id: 0,
            children: []
          }
        ];
        let tpCount = 0,
          tpNow = 0;
        objData.forEach((item) => {
          if (tpNow <= 1) {
            if (tpCount >= 3) {
              that.optData.push({
                id: tpNow + 1,
                children: [item]
              });
              tpCount = 1;
              tpNow++;
            } else {
              that.optData[tpNow].children.push(item);
              tpCount++;
            }
          }
        });
      }
    },
    onGetSurname(objItem) {
      return regNull(objItem) ? '' : objItem.substring(0, 1);
    }
  },
  filters: {
    flLogDate(objItem) {
      let nowTime = timeTransform(new Date(), 'YYYY-MM-DD');
      let listTime = timeTransform(objItem, 'YYYY-MM-DD');
      if (compareDate(listTime, nowTime)) {
        let tpTime = timeTransform(objItem, 'HH:mm');
        return tpTime == '00:00' ? timeTransform(objItem, 'MM月DD日') : timeTransform(objItem, 'HH:mm');
      } else {
        return timeTransform(objItem, 'MM月DD日');
      }
    },
    flGetName(objItem) {
      if (regNull(objItem)) {
        return '***';
      } else {
        if (objItem.length <= 2) {
          return objItem[0] + '*';
        } else {
          let tpFirst = objItem[0];
          let tpLst = objItem[objItem.length - 1];
          let minLength = objItem.length - 2;
          let char = [...new Array(minLength).fill('*')];
          return tpFirst + char.join('') + tpLst;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.evaluate-body {
  padding: 30px;
  .divNullData {
    padding-top: 30%;
  }

  .divTitle {
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .divItemList {
    display: flex;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    .divAva {
      span {
        color: #fff;
        background-color: #2596b4;
        height: 45px;
        width: 45px;
        border-radius: 5px;
        font-weight: bold;
        overflow: hidden;
      }
    }

    .divInfo {
      margin-left: 30px;
      width: calc(~'100% - 120px');
      .spName {
        margin-right: 5px;
      }
      .spClass {
        font-size: 14px;
      }
    }

    .divTime {
      font-size: 14px;
      text-align: right;
      width: 100px;
    }
  }
  .item-body-left {
    height: 370px;
    .el-carousel__container {
      height: 370px;
    }
  }
}

.bottom-right-table-1 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1601px) {
  .evaluate-body {
    padding: 20px;
    .divTitle {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .divItemList {
      padding-top: 15px;
      padding-bottom: 15px;

      .divAva {
        span {
          height: 45px;
          width: 50px;
          font-size: 14px;
        }
      }

      .divInfo {
        margin-left: 30px;
        width: calc(~'100% - 120px');
        .spName {
          margin-right: 5px;
        }
        .spClass {
          font-size: 14px;
        }
      }

      .divTime {
        font-size: 14px;
      }
    }
    .item-body-left {
      height: 310px;
    }
  }
}

@media screen and (max-width: 1441px) {
  .evaluate-body {
    padding: 18px;
    .divTitle {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .divItemList {
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      .divAva {
        span {
          height: 45px;
          width: 50px;
          font-size: 14px;
        }
      }

      .divInfo {
        margin-left: 30px;
        width: calc(~'100% - 100px');
        font-size: 14px;
        .spName {
          margin-right: 5px;
        }
        .spClass {
          font-size: 14px;
        }
      }

      .divTime {
        font-size: 14px;
        text-align: right;
      }
    }
    .item-body-left {
      height: 310px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .evaluate-body {
    padding: 18px;
    .divTitle {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .divItemList {
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      .divAva {
        span {
          height: 45px;
          width: 50px;
          font-size: 14px;
        }
      }

      .divInfo {
        margin-left: 30px;
        width: calc(~'100% - 100px');
        font-size: 14px;
        .spName {
          margin-right: 5px;
        }
        .spClass {
          font-size: 14px;
        }
      }

      .divTime {
        font-size: 14px;
        text-align: right;
      }
    }
    .item-body-left {
      height: 310px;
    }
  }
}
</style>
