<template>
  <div>
    <!-- <div v-if="!isShowScreen">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            大数据展示配置
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="20">
            <el-col :lg="8" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">学期</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="secForm.SemesterId" filterable placeholder="请选择" class="width_100Pie" @change="onChangeSemeser">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="8" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">月份</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="secForm.Month" filterable clearable placeholder="可选择月份" class="width_100Pie">
                  <el-option v-for="item in optMonth" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="card-footer text-center">
          <el-button type="primary" icon="el-icon-search" @click="onSearch()">生成大数据展示页</el-button>
        </div>
      </el-card>
    </div>
    <div v-else>
      <comDatav :comData="itemData" :comMonth="secForm.Month" ref="comDatav"></comDatav>
    </div> -->

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          大数据展示配置
        </h3>
      </div>
      <div class="card-body">
        <div class="body-box">
          <el-button type="primary" icon="el-icon-search" @click="onGetPage" class="btnPage">跳转至驾驶舱大数据展示页面</el-button>
          <div class="midBox">或者</div>
          <div class="copyBox">
            <el-input v-model="txtCopy" class="txtCopy" :readonly="true"></el-input>
          </div>
        </div>
        <div class="t-c">建议在1920 x 1080，1600 x 900，1400 x 810，1366 x 768，1280 x 720这几类分辨率中浏览，效果最佳。</div>
        <div class="t-c">按 F11 可全屏浏览</div>
        <br />
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import screenfull from 'screenfull';
// import comDatav from '@/components/WebBigScreen/index.vue';
export default {
  components: {
    // comDatav
  },
  data() {
    return {
      isFullscreen: false,
      isShowScreen: false,
      secForm: {
        SemesterId: null,
        Month: null
      },
      secFormReset: null,
      optSemester: [], // 学期
      optMonth: [], // 月份

      itemData: [],
      txtCopy: null
    };
  },
  created() {
    let that = this;
    that.txtCopy = that.$WebUrl() + '/#/Cockpit';
    /*
    //获取学期
    ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
      if (!regNullArray(resSemester)) {
        that.optSemester = resSemester;
        // 获取当前学期
        let currentSemester = resSemester.find((val) => val.isCurrent == true).semesterId;
        // 设置默认学期
        if (!regNull(currentSemester)) {
          that.secForm.SemesterId = currentSemester;
        } else {
          that.secForm.SemesterId = resSemester[0].semesterId;
        }

        if (!regNull(that.secForm.SemesterId)) {
          // 获取当前学期的所有月份
          ajaxGet(
            that,
            '/api/admin/semesterday/search',
            {
              SemesterId: that.secForm.SemesterId
            },
            function (resDay) {
              if (resDay && resDay.length > 0) {
                resDay.forEach((element) => {
                  let monthVal = timeTransform(element.day, 'YYYY-MM');
                  if (that.optMonth.indexOf(monthVal) == -1) {
                    that.optMonth.push(monthVal);
                  }
                });
              }
              that.secFormReset = JSON.parse(JSON.stringify(that.secForm));
            }
          );
        }
      }
    });
    */
  },
  mounted() {
    // let that = this;
    // that.onWebInit();
  },
  beforeDestroy() {
    // let that = this;
    // setTimeout(function () {
    //   if (regNull(!that.$refs.comDatav)) {
    //     that.$refs.comDatav.onClearBigScreen();
    //   }
    //   return false;
    // }, 200);
    // that.onWebDestroy();
  },
  methods: {
    onGetPage() {
      window.open(this.$WebUrl() + '/#/Cockpit', '_blank');
    },

    /**
     * @description 学期选择事件
     * @param {Object} objItem
     */
    onChangeSemeser(objItem) {},
    /**
     * @description 传值并开始大屏展示
     */
    onSearch() {
      let that = this;
      if (!screenfull.isEnabled) {
        that.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning'
        });
        return false;
      }

      let tpSemester = that.optSemester.find((item) => item.semesterId == that.secForm.SemesterId);
      if (!regNull(tpSemester)) {
        let tpParams = {
          SemesterId: that.secForm.SemesterId,
          Name: tpSemester.name,
          Month: that.secForm.Month
        };
        that.isShowScreen = true;
        screenfull.toggle();

        setTimeout(function () {
          if (regNull(!that.$refs.comDatav)) {
            that.$refs.comDatav.onGetBigScreen(tpParams);
          }
          return false;
        }, 1500);
      }
    },
    /**
     * @description 开启全屏或退出全屏触发事件
     */
    onChangeWebFullScreen() {
      let that = this;
      that.isFullscreen = screenfull.isFullscreen;
      if (!screenfull.isFullscreen) {
        confirmDelete(that, '是否确认退出大数据展示页面', function (res) {
          if (res == true) {
            that.isShowScreen = false;
            that.$refs.comDatav.onStopTime();
          }
        });
      }
    },
    /**
     * @description 开启全屏时的监控
     */
    onWebInit() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.onChangeWebFullScreen);
      }
    },
    /**
     * @description 页面关闭时退出全屏
     */
    onWebDestroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.onChangeWebFullScreen);
      }
    }
  }
};
</script>

<style scoped="scoped" lang="less">
.body-box {
  padding-top: 5%;

  text-align: center;
  .midBox {
    padding: 15px;
  }
  .copyBox {
    text-align: center;
    padding-bottom: 60px;
  }
  .btnPage,
  .txtCopy {
    width: 300px;
  }
}

.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
}
</style>
