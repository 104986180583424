<!--
  基础信息 - 班级德育分原因配置
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <!-- <div style="float: left;"> -->
        <h3 class="card-title titleCont" style="padding-left: 0px; line-height: 30px">班级德育分原因</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-select v-model="searchForm.classId" placeholder="请选择班级" @change="onChange()">
                <el-option v-for="item in optClass" :key="item.classId" :label="item.name" :value="item.classId"></el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <!-- </div> -->
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>
              <el-tree
                :data="data"
                :props="defaultProps"
                ref="tree"
                style="margin-top: 10px"
                node-key="id"
                default-expand-all
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
                :render-content="renderContent"
                :filter-node-method="filterNode"
              ></el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p, index) in currentNav" :key="index">{{ p }}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>

              <el-col :span="4" style="text-align: right">
                <el-button
                  v-if="
                    isPower.moralityclassreason_type_create == true ||
                    isPower.classcreditlogreasons_create == true ||
                    isPower.dormitoryreason_type_create == true ||
                    isPower.moralitydpreason_type_create == true
                  "
                  @click="onAdd()"
                  type="success"
                  icon="el-icon-circle-plus-outline"
                >
                  新增
                </el-button>
              </el-col>
            </el-row>
            <el-table stripe :data="item" class="tableTwo table-hover text-nowrap" style="width: 100%; margin-top: 0px">
              <el-table-column type="index" width="120px;" label="序号"></el-table-column>
              <el-table-column prop="name" label="原因名称"></el-table-column>
              <!--<el-table-column prop="weight" label="权重"></el-table-column>!-->
              <el-table-column label="操作" width="240px">
                <template slot-scope="scope">
                  <operationBtn v-if="isPower.classcreditlogreasons_edit == true" :btnName="'编辑'" @click.native="onEdit(scope.row.reasonId)"></operationBtn>
                  <operationBtn v-if="isPower.classcreditlogreasons_delete == true" :btnName="'删除'" @click.native="onDelete(scope.row.reasonId)"></operationBtn>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
export default {
  name: '',
  components: {
    operationBtn
  },
  data() {
    return {
      cols: 24,
      top: 50,
      filterText: '', //查找树形菜单
      currentNav: [],
      random: 0, //刷新组件
      currentId: null,
      isDetail: false,
      dialogTitle: '新增综合类型',
      dialogVisible: false,
      currentTitle: '请在左侧选择综合类型',
      item: null,
      fullscreenLoading: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      ruleForm: {
        reasonId: null,
        name: null,
        score: null,
        priority: null,
        parent: null,
        parentId: null,
        description: null,
        classId: null
      },
      searchForm: {
        classId: null
      },
      ruleFormInit: null,
      ruleFormReset: null,
      currentType: 1,
      isConfig: false,
      isPower: {
        //增删改查 权限管理
        classcreditlogreasons_create: false,
        classcreditlogreasons_edit: false,
        classcreditlogreasons_delete: false,
        classcreditlogreasons_details: false
      },
      rules: {
        name: [
          { required: true, message: '扣分类型不能为空', trigger: 'change' },
          { max: 64, message: '扣分类型不能超过64个字符', trigger: 'change' }
        ],
        priority: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('优先级只能输入整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      TeacherInfo: {},
      optClass: []
    };
  },
  created() {
    let that = this;
    isPower(that, 'classcreditlogreasons_create', 'classcreditlogreasons_create');
    isPower(that, 'classcreditlogreasons_edit', 'classcreditlogreasons_edit');
    isPower(that, 'classcreditlogreasons_delete', 'classcreditlogreasons_delete');
    isPower(that, 'classcreditlogreasons_details', 'classcreditlogreasons_details');
    that.TeacherInfo = onGetNowUser(that);
    let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
    if (!that.TeacherInfo.isTeacher) {
      that.optClass = [
        {
          classId: null,
          name: '全校通用'
        }
      ];
      resultClass.forEach((item) => {
        that.optClass.push({
          classId: item.classId,
          name: item.alias
        });
      });
    } else {
      that.optClass = resultClass.map((item) => ({
        classId: item.classId,
        name: item.alias
      }));
      that.searchForm.classId = resultClass[0].classId;
    }
    InitType(that);
  },
  methods: {
    onChange() {
      let that = this;
      Init(that);
    },
    onFilterText() {
      this.filterText = '';
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    onPriorityChange() {
      let that = this;
      var value = setInt(that.ruleForm.priority);
      that.ruleForm.priority = value;
    },
    onDetails() {
      let that = this;
    },
    onAdd() {
      let that = this;
      that.$router.push({
        path: '/Admin/MoralEducationReason/Create',
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onEdit(_dataID) {
      let that = this;
      that.$router.push({
        path: '/Admin/MoralEducationReason/Update/' + _dataID,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDelete(id) {
      let that = this;
      // var deleteUrl = "";
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/admin/classcreditreason/' + id, null, function (r) {
            that.fullscreenLoading = false;
            Init(that);
          });
        }
      });
      // confirmDelete(that, "/api/admin/classcreditreason/" + id, function (res) {
      //   if (res == true) {
      //     that.fullscreenLoading = true;
      //     ajaxDelete(that, deleteUrl, null, function (r) {
      //       that.fullscreenLoading = false;
      //       Init(that);

      //     });
      //   }
      // })
    },
    handleNodeClick(data) {
      let that = this;
      that.item = data.children;
      that.currentTitle = data.name;
      that.currentId = data.reasonId;
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span style="font-size:14px;">{node.label}</span>
        </span>
      );
    }
  },
  watch: {
    currentTitle(val) {
      let that = this;
      console.log(val);
      var titles = val.split('/');
      that.currentNav = ['扣分类型'];
      for (var i in titles) {
        if (titles[i] != '扣分类型') {
          that.currentNav.push(titles[i]);
        }
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    $route() {
      let that = this;
      InitType(that);
    }
  },
  mounted() {}
};

function InitType(that) {
  Init(that);
}

function GetItem(that, val) {
  if (val.length !== 0) {
    val.forEach((item) => {
      if (item.id == that.currentId) {
        that.item = item.children;
        that.currentTitle = item.title;
      } else if (item.children.length >= 1) {
        GetItem(that, item.children);
      }
    });
  }
}

function Init(that) {
  var listUrl = '';
  that.fullscreenLoading = true;
  that.data = [];
  that.item = [];
  let data = {
    classId: that.searchForm.classId
  };
  ajaxGet(that, '/api/admin/classcreditreason/treelist', data, function (r) {
    that.fullscreenLoading = false;
    //that.data = r;
    var tree = {
      id: null,
      reasonId: null,
      value: null,
      name: '班级德育分原因',
      title: '班级德育分原因',
      children: r
    };

    that.data.push(tree);
    GetItem(that, that.data);
    that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
  });
}
</script>

<style scoped="scoped">
.divTreeCont {
  border: 1px solid #dddddd;
  padding: 15px;
  border-radius: 4px;
}

.divTreeCont .el-tree-node__content {
  height: 40px;
}

.divTreeCont .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 15px;
}

.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}
</style>
