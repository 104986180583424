<!--
  班干部管理
-->
<template>
  <div class="SubWebPage LiteracyCoursePage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-form label-width="60px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title">搜索</h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="12" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">班级</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.classId" class="width_100Pie" placeholder="请选择" filterable>
                  <el-option v-for="(item, index) in optClass" :key="index" :label="item.alias" :value="item.classId"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="card-footer text-center">
          <el-button type="primary" slot="append" icon="el-icon-search" @click="onSearch()">查询</el-button>
          <el-button slot="append" icon="el-icon-refresh" @click="onResetSearch()">重置</el-button>
        </div>
      </el-card>
    </el-form>

    <div class="card-tools" style="margin-bottom: 15px; overflow: hidden">
      <ul class="nav" style="float: right">
        <li class="nav-item" style="float: right">
          <!--导入-->
          <comImport
            @onImportSuccess="onImportSuccess"
            :template="'/templates/班干部信息导入模版.xlsx'"
            :path="uploadInfoApi"
            :btnIcon="'el-icon-upload2'"
            :title="'批量导入'"
            :btnName="'导入'"
            ref="conImportInfoFile"
            v-if="isPower.classcadremgt_import == true"
          ></comImport>
        </li>
        <li class="nav-item" style="float: right">
          <el-button v-if="isPower.classcadremgt_export == true" @click="onOpenExport()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
        </li>
      </ul>
    </div>

    <el-card class="box-card" v-for="(item, index) in itemData" :key="index">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">
          {{ item.name }}
          <small>
            （
            <b class="color-danger">{{ regNullArray(item.StudentList) ? 0 : item.StudentList.length }}</b>
            / {{ item.number == null ? '无限制' : item.number }}）
          </small>
        </h3>
        <div class="card-tools" v-if="item.StudentList != null">
          <ul class="nav" v-if="(item.number == null || item.number > item.StudentList.length) && isPower.classcadremgt_create">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" size="mini" @click="onCreate(item)">设置</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item.StudentList != null && item.StudentList.length != 0">
        <el-table stripe :data="item.StudentList" class="elTableData">
          <el-table-column prop="studentNo" label="学号" min-width="120"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
          <el-table-column prop="studentName" label="权限" min-width="300">
            <template slot-scope="scope">
              <span v-if="scope.row.isCredit || scope.row.isMorality">
                <el-tag size="mini" v-if="scope.row.isCredit">班级德育分</el-tag>
                &nbsp;
                <el-tag size="mini" v-if="scope.row.isMorality">行为规范落实</el-tag>
                &nbsp;
              </span>
              <el-tag size="mini" type="info" v-else>无其它权限</el-tag>
              &nbsp;
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="160" v-if="isPower.classcadremgt_edit || isPower.classcadremgt_delete">
            <template slot-scope="scope">
              <!-- 管理员 -->
              <operationBtn :btnName="'编辑'" v-if="isPower.classcadremgt_edit" @click.native="onEdit(scope.row)"></operationBtn>
              <operationBtn :btnName="'撤职'" v-if="isPower.classcadremgt_delete" @click.native="onDelete(scope.row.cadreId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <div class="divNullDataBox VerticalMid">暂未设置班干部</div>
      </div>
    </el-card>

    <!--弹出框-->
    <el-dialog :title="'设置' + ruleForm.name" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="row">
        <div class="col-lg-12 colInfo">
          <label>班级</label>
          <div class="colFonts pr-4">
            <el-select v-model="ruleForm.classId" class="width_100Pie" placeholder="请选择" filterable @change="onChangeClass">
              <el-option v-for="(item, index) in optClass" :key="index" :label="item.alias" :value="item.classId"></el-option>
            </el-select>
          </div>
        </div>
        <div class="col-lg-12 colInfo">
          <label>学生</label>
          <div class="colFonts pr-4">
            <el-select v-model="ruleForm.studentId" placeholder="请选择" class="width_100Pie">
              <el-option v-for="item in optStudent" :key="item.studentId" :label="item.name + '【' + item.studentNo + '】'" :value="item.studentId"></el-option>
            </el-select>
          </div>
        </div>
        <div class="col-lg-12 colInfo">
          <label>班干部权限</label>
          <div class="colFonts">
            <el-checkbox v-model="ruleForm.isCredit">班级德育分</el-checkbox>
            <el-checkbox v-model="ruleForm.isMorality">行为规范落实</el-checkbox>
          </div>
        </div>
        <div class="col-lg-12 colInfo">
          <label>备注</label>
          <div class="colFonts pr-4">
            <el-input v-model="ruleForm.remark" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="'学生：' + ruleForm.studentName" :visible.sync="dialogVisibleEdit" width="30%" :before-close="handleClose">
      <div class="row">
        <div class="col-lg-12 colInfo">
          <label>班干部职务</label>
          <div class="colFonts pr-4">
            {{ ruleForm.position }}
          </div>
        </div>
        <div class="col-lg-12 colInfo">
          <label>班干部权限</label>
          <div class="colFonts">
            <el-checkbox v-model="ruleForm.isCredit">班级德育分</el-checkbox>
            <el-checkbox v-model="ruleForm.isMorality">行为规范落实</el-checkbox>
          </div>
        </div>
        <div class="col-lg-12 colInfo">
          <label>备注</label>
          <div class="colFonts pr-4">
            <el-input v-model="ruleForm.remark" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comImport from '@/components/Import.vue';
import operationBtn from '@/components/operationBtn.vue';
export default {
  components: {
    operationBtn,
    comImport
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      dialogVisible: false,
      dialogVisibleEdit: false,
      SearchList: null,

      uploadInfoApi: '/api/admin/classcadre/import',
      itemData: {},
      searchForm: {
        semesterId: null,
        classId: null
      },
      searchFormReset: null,
      ruleForm: {
        name: null,
        studentId: null,
        positionId: null,
        classId: null,
        semesterId: null,
        isCredit: false,
        isMorality: false,
        studentName: null,
        remark: null
      },
      ruleFormReset: null,
      optStudent: [],
      optSemester: [],
      optClass: [],
      //权限
      isPower: {
        // 增删改查 权限管理
        classcadremgt_edit: false,
        // 领导浏览
        classcadremgt_user: false,
        classcadremgt_create: false,
        classcadremgt_delete: false,
        classcadremgt_import: false,
        classcadremgt_export: false
      },

      TeacherInfo: {} // 教师信息
    };
  },
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);
    isPower(that, 'classcadremgt_edit', 'classcadremgt_edit');
    isPower(that, 'classcadremgt_user', 'classcadremgt_user');
    isPower(that, 'classcadremgt_create', 'classcadremgt_create');
    isPower(that, 'classcadremgt_delete', 'classcadremgt_delete');
    isPower(that, 'classcadremgt_import', 'classcadremgt_import');
    isPower(that, 'classcadremgt_export', 'classcadremgt_export');
    let resClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
    if (!regNullArray(resClass)) {
      // 默认学期显示当前学期
      that.optClass = JSON.parse(JSON.stringify(resClass));
      // 设置默认值
      that.ruleForm.classId = JSON.parse(JSON.stringify(resClass))[0].classId;
      that.searchForm.classId = JSON.parse(JSON.stringify(resClass))[0].classId;

      // 获取学生信息
      ajaxGet(that, '/api/admin/student/class/' + that.ruleForm.classId, null, function (resStudent) {
        that.optStudent = resStudent;
      });

      that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
    }
    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
    pagingPage(that);
  },
  methods: {
    onOpenExport() {
      let that = this;
      that.fullscreenLoading = true;
      let data = {
        ClassId: that.TeacherInfo.isTeacher ? that.ruleForm.classId : ''
      };
      ajaxExport(that, '/api/admin/classcadre/export', null, '班干部信息.xlsx', function (resData) {
        that.fullscreenLoading = false;
      });
    },
    onChangeClass() {
      let that = this;
      that.ruleForm.studentId = null;
      that.optStudent = [];
      // 获取学生信息
      ajaxGet(that, '/api/admin/student/class/' + that.ruleForm.classId, null, function (resStudent) {
        that.optStudent = resStudent;
      });
    },
    onImportSuccess(param) {
      let that = this;
      pagingPage(that);
    },
    /**
     * @description 新增班干部
     * @param {Object} objVal
     */
    onCreate(objVal) {
      let that = this;
      that.ruleForm.positionId = objVal.positionId;
      that.ruleForm.name = objVal.name;

      that.dialogVisible = true;
    },
    onEdit(objVal) {
      let that = this;
      that.dialogVisibleEdit = true;
      // that.ruleForm = objVal;
      this.ruleForm = { ...objVal };
    },
    onUpdate() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxPut(that, '/api/admin/classcadre/auth/' + that.ruleForm.cadreId, that.ruleForm, function (res) {
        that.fullscreenLoading = false;
        pagingPage(that);
        that.handleClose();
      });
    },
    /**
     * @description 新增班干部数据
     */
    onSave() {
      let that = this;
      if (regNull(that.ruleForm.studentId)) {
        warning(that, '请选择学生');
        return false;
      }
      that.fullscreenLoading = true;
      ajaxPost(that, '/api/admin/classcadre/mobile', that.ruleForm, function (res) {
        that.fullscreenLoading = false;
        pagingPage(that);
        that.handleClose();
      });
    },
    /**
     * @description 撤职
     * @param {Object} objID
     */
    onDelete(objID) {
      let that = this;
      let mes = '是否确认撤职该学生的班干部职务？';
      confirmDelete(that, mes, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          let tempData = [objID];
          ajaxPut(that, '/api/admin/classcadre/' + objID, tempData, function (res) {
            that.fullscreenLoading = false;
            pagingPage(that);
          });
        }
      });
    },

    // 搜索
    onSearch() {
      pagingPage(this);
    },

    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      pagingPage(that);
    },

    /**
     * @description 关闭弹出框
     */
    handleClose() {
      let that = this;
      that.dialogVisible = false;
      that.dialogVisibleEdit = false;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      // pagingPage(that)
    }
  },
  watch: {}
};

function pagingPage(that) {
  that.fullscreenLoading = true;

  // 接口需要的值
  let tempData = {
    // SemesterId: that.searchForm.semesterId,
    ClassId: that.searchForm.classId
  };

  ajaxGet(that, '/api/admin/classposition/enable', null, function (resPosition) {
    that.itemData = resPosition;
    // 获取班干部数据
    ajaxGet(that, '/api/admin/classcadre/search', tempData, function (resCadre) {
      that.itemData.forEach(function (item) {
        item.StudentList = resCadre.filter((list) => list.positionId == item.positionId);
      });
      that.ruleForm.classId = that.searchForm.classId;
      that.onChangeClass();
      that.fullscreenLoading = false;
    });
  });
}
</script>

<style scoped="scoped">
.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.selBodyBox {
  padding-top: 5px;
}
.divTitle {
  text-align: center;
  font-size: 18px;
}

.divSelectBox {
  padding-top: 15px;
}

.titleSmall {
  font-size: 14px !important;
}

.divStudentBox {
  height: 70px;
  overflow-y: auto;
}

.divStudentBox .spLabel {
  border: 1px solid #dddddd;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
  margin: 2px 5px;
  display: inline-block;
  white-space: nowrap;
}

.divNullDataBox {
  height: 70px;
  color: #9e9e9e;
}

.spNowNum {
  color: #fa3534;
  font-weight: bold;
}
</style>
