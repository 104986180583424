<template>
  <div>
    <comDatav ref="comDatav"></comDatav>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import screenfull from 'screenfull';
import comDatav from '@/components/WebBigScreen/index.vue';
export default {
  components: {
    comDatav
  },
  data() {
    return {
      isFullscreen: false,
      isShowScreen: false,
      itemData: []
    };
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.onSearch();
      return false;
    }, 1500);
  },
  mounted() {
    let that = this;
    that.onWebInit();
  },
  beforeDestroy() {
    let that = this;
    setTimeout(function () {
      if (regNull(!that.$refs.comDatav)) {
        that.$refs.comDatav.onClearBigScreen();
      }
      return false;
    }, 200);
    that.onWebDestroy();
  },
  methods: {
    /**
     * @description 学期选择事件
     * @param {Object} objItem
     */
    onChangeSemeser(objItem) {},
    /**
     * @description 传值并开始大屏展示
     */
    onSearch() {
      let that = this;
      if (!screenfull.isEnabled) {
        that.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning'
        });
        return false;
      }

      that.isShowScreen = true;
      screenfull.toggle();

      setTimeout(function () {
        if (regNull(!that.$refs.comDatav)) {
          that.$refs.comDatav.onGetBigScreen();
        }
        return false;
      }, 1500);
    },
    /**
     * @description 开启全屏或退出全屏触发事件
     */
    onChangeWebFullScreen() {
      let that = this;
      that.isFullscreen = screenfull.isFullscreen;
      if (!screenfull.isFullscreen) {
        confirmDelete(that, '是否确认退出大数据展示页面', function (res) {
          if (res == true) {
            that.isShowScreen = false;
            that.$refs.comDatav.onStopTime();
          }
        });
      }
    },
    /**
     * @description 开启全屏时的监控
     */
    onWebInit() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.onChangeWebFullScreen);
      }
    },
    /**
     * @description 页面关闭时退出全屏
     */
    onWebDestroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.onChangeWebFullScreen);
      }
    }
  }
};
</script>

<style scoped="scoped" lang="less">
.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
}
</style>
