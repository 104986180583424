<!--
  综合活动 活动详情
-->
<template>
  <div class="SubWebPageStudent DetailsPro">
    <div class="divBreadcrumbCont">
      <router-link to="/Student/Home/Index" class="alinkUrl">首页</router-link>
      /
      <router-link to="/Student/LiteracyActivities/Index" class="alinkUrl">活动管理</router-link>
      /
      <span class="spNowUrl">活动详情</span>
    </div>
    <!--活动报名-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看活动详情
        </h3>
      </div>
      <div class="card-body p-0">
        <el-row :gutter="24" style="padding-top: 20px; padding-bottom: 30px" v-if="imgArr != ''">
          <el-col :span="8">
            <el-carousel height="300px" style="padding-left: 20px">
              <el-carousel-item v-for="(item, index) in imgArr" :key="index">
                <img :src="item" alt="" width="100%" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="16">
            <div class="infoList clearfix">
              <span class="spName">{{ itemData.name }}</span>
            </div>
            <div class="infoList clearfix" v-if="itemData.applyStartDate != null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-clock-o"></use>
                  </svg>
                  &nbsp; 报名时间
                </span>
                <span>{{ itemData.applyStartDate | dateformatMinute }} 至 {{ itemData.applyEndDate | dateformatMinute }}</span>
              </span>
            </div>
            <div class="infoList clearfix" v-if="itemData.startDate != null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-date-clock-o"></use>
                  </svg>
                  &nbsp; 活动时间
                </span>
                &nbsp;
                <span>{{ itemData.startDate | dateformatMinute }} 至 {{ itemData.endDate | dateformatMinute }}</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <!--  -->
              <span class="spFonts" v-if="itemData.numberLimit != null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp; 报名人数
                </span>
                &nbsp;
                <span class="spNum">{{ itemData.numberLimit }}</span>
              </span>
              <span class="spFonts" v-if="itemData.place != null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-fax-map-marker-o"></use>
                  </svg>
                  &nbsp; 地址
                </span>
                {{ itemData.place }}
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="padding-top: 20px; padding-bottom: 30px" v-else>
          <el-col :span="24">
            <div class="infoList clearfix">
              <span class="spName">{{ itemData.name }}</span>
            </div>
            <div class="infoList clearfix" v-if="itemData.applyStartDate != null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-clock-o"></use>
                  </svg>
                  &nbsp; 报名时间
                </span>
                <span>{{ itemData.applyStartDate | dateformatMinute }} 至 {{ itemData.applyEndDate | dateformatMinute }}</span>
              </span>
            </div>
            <div class="infoList clearfix" v-if="itemData.startDate != null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-date-clock-o"></use>
                  </svg>
                  &nbsp; 活动时间
                </span>
                &nbsp;
                <span>{{ itemData.startDate | dateformatMinute }} 至 {{ itemData.endDate | dateformatMinute }}</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <!--  -->
              <span class="spFonts" v-if="itemData.numberLimit != null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp; 报名人数
                </span>
                &nbsp;
                <span class="spNum">{{ itemData.numberLimit }}</span>
              </span>
              <span class="spFonts" v-if="itemData.place != null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-fax-map-marker-o"></use>
                  </svg>
                  &nbsp; 地址
                </span>
                {{ itemData.place }}
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--活动内容-->
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动内容
        </h3>
      </div>
      <div class="card-body p-0">
        <el-row :gutter="24" style="padding-top: 20px; padding-bottom: 30px; padding-left: 20px; padding-right: 20px">
          <el-col :span="24">
            <div class="colRemarksCont" v-if="itemData.description != null">{{ itemData.description }}</div>
            <div class="divNullDataBox VerticalMid" v-else>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-exclamation-triangle-o"></use>
              </svg>
              &nbsp; 活动内容暂无数据
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--参与人员-->
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          参与人员
        </h3>
      </div>
      <div class="card-body p-0">
        <el-row :gutter="24" style="padding-top: 10px; padding-bottom: 20px; padding-left: 20px; padding-right: 20px">
          <el-col :span="24">
            <div class="card-body tabRoleCont" v-for="(p, index) in configData" :key="index">
              <div class="divRoleTitle">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-user"></use>
                </svg>
                {{ p.roleName }}
                <span class="spItem">
                  <el-tooltip placement="top">
                    <div slot="content">
                      <div v-for="(p1, index) in p.configList" :key="index">{{ p1.config.name }} {{ p1.score }}</div>
                    </div>
                    <el-button>
                      <em>{{ p.totalScore }}</em>
                      分
                    </el-button>
                  </el-tooltip>
                </span>
              </div>
              <div class="row">
                <div class="col-lg-3" v-for="(p2, index) in p.roster" :key="index">
                  <div class="stuInfo">
                    <div class="stuImgCont">
                      <img :src="p2.photo" alt="" />
                    </div>
                    <div class="stuName">{{ p2.studentName }}&nbsp;</div>
                    <div class="stuOther">{{ p2.studentNo }}</div>
                    <div class="stuRole" v-if="p2.status == 1"><span class="spStatus">待报名审核</span></div>
                    <div class="stuRole" v-if="p2.status == 2"><span class="spStatus color-success">报名审核不通过</span></div>
                    <div class="stuRole" v-if="p2.status == 3"><span class="spStatus color-danger">报名审核通过</span></div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--立即报名-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card" style="margin-top: 10px">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            报名角色
          </h3>
        </div>
        <div class="card-body p-0">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="活动角色" prop="roleId">
                <el-select v-model="ruleForm.roleId" placeholder="请选择报名角色">
                  <el-option v-for="item in configData" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-check" @click="onEnroll()">立即报名</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Student.css';
export default {
  data() {
    return {
      // 动画
      fullscreenLoading: false,
      dialogVisible: false,
      //列表数据
      itemData: [],
      configData: [],
      imgArr: [],
      ruleForm: {
        activityId: null,
        roleId: null
      },
      rules: {
        roleId: [
          {
            required: true,
            message: '请选择活动角色',
            trigger: 'change'
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    onBack() {
      let that = this;
      back(that);
    },
    onEnroll() { //立即报名
      let that = this;
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          that.fullscreenLoading = true;
          ajaxPost(that, '/api/student/activity/enroll/' + that.ruleForm.activityId + '?roleId=' + that.ruleForm.roleId, null, function (r) {
            that.fullscreenLoading = false;
            that.onBack();
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    }
  },
  created() {
    let that = this;
    let id = that.$route.params.id;
    that.ruleForm.activityId = id;
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/student/activity/' + id, null, function (r) {
      ajaxGet(that, '/api/student/activity/roster/' + id, null, function (r1) {
        if (r.attachment != null) {
          var attachments = r.attachment.split('|');
          for (var i in attachments) {
            that.imgArr.push(that.$ServerUrl() + attachments[i]);
          }
        }
        that.fullscreenLoading = false;
        that.itemData = r;

        // that.Configs=r.configs;
        var configs = {
          roleName: null,
          config: {
            name: null,
            score: null
          },
          totalScore: null,
          roster: []
        };
        var roleName = [];
        for (var i in r.configs) {
          if (roleName.indexOf(r.configs[i].roleName) < 0) {
            roleName.push(r.configs[i].roleName);
          }
        }
        var roleId = [];
        for (var i in r.configs) {
          if (roleId.indexOf(r.configs[i].roleId) < 0) {
            roleId.push(r.configs[i].roleId);
          }
        }

        for (var i in roleName) {
          var configs = {
            roleName: roleName[i],
            roleId: roleId[i],
            configList: [],
            totalScore: 0,
            roster: [],
            checked: false
          };
          for (var j in r.configs) {
            if (roleName[i] == r.configs[j].roleName) {
              configs.configList.push(r.configs[j]);
              configs.totalScore = configs.totalScore + r.configs[j].score;
            }
          }
          for (var j in r1) {
            if (roleName[i] == r1[j].roleName) {
              r1[j].photo = that.$ServerUrl() + r1[j].photo;
              configs.roster.push(r1[j]);
            }
          }
          that.configData.push(configs);
        }
      });
    });
  }
};
</script>

<style scoped="scoped">
.dialogBox {
  padding: 10px 5px;
  height: 140px;
  overflow: hidden;
}

.dialogVideoBox {
  padding: 10px 5px;
}

.dialogBox .mailbox-attachment-icon {
  font-size: 32px;
  padding: 15px 10px;
}

.dialogBox .mailbox-attachment-size > span {
  font-size: 12px;
}

.dialogBox .mailbox-attachment-info {
  padding: 7px 10px;
}

.bodyAwardsCont .stuAwardsCont {
  position: relative;
  padding: 0px 15px 45px 15px;
}

.bodyAwardsCont .stuAwardsCont .stuAvatar {
  position: absolute;
  left: 15px;
  top: 0px;
  border: 1px solid #eee;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.bodyAwardsCont .stuAwardsLabel {
  font-size: 12px;
  padding-left: 65px;
  color: #b1b1b1;
}

.bodyAwardsCont .awardsName {
  padding-top: 10px;
  color: #3e3e3e;
}

.bodyAwardsCont .stuAwardsCont .stuName {
  font-size: 16px;
  padding-left: 65px;
}

.bodyAwardsCont .stuAwardsFont {
  font-size: 14px;
  padding-left: 65px;
  color: #666;
}

.bodyAwardsCont .stuFileCont {
  padding-top: 5px;
}

.bodyRecordCont .stuRecordCont {
  position: relative;
  padding: 0px 15px 45px 15px;
  padding-left: 75px;
}

.bodyRecordCont .stuRecordCont .stuAvatar {
  position: absolute;
  left: 15px;
  top: 0px;
  border: 1px solid #eee;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.bodyRecordCont .stuRecordCont .stuName {
  font-size: 16px;
}

.bodyRecordCont .stuRecordFont {
  font-size: 12px;
  padding-top: 5px;
  color: #767676;
}

.bodyRecordCont .stuRecordLabel {
  font-size: 12px;
  padding-top: 5px;
  color: #b1b1b1;
}

.bodyRecordCont .stuFileCont {
  padding-top: 10px;
}

.bodyRecordCont .stuFileCont .divFileIcon {
  border: 1px solid #eee;
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-radius: 4px;
}

.bodyRecordCont .stuFileCont .divFileIcon .elIcon {
  font-size: 32px;
}

.colRecordOther {
}

.colRecordOther .divList {
  float: left;
  width: 33.3%;
}

.colRecordCont {
  padding: 5px;
  font-size: 12px;
  color: #767676;
}

.divVideoCont {
  overflow-y: auto;
  width: 100%;
  height: 500px;
  padding-right: 10px;
}

.divVideoCont ul li .aItem {
  display: block;
  padding: 7px 0px;
  padding-left: 30px;
  border-bottom: 1px dotted #dddddd;
  position: relative;
}

.divVideoCont ul li:last-child .aItem {
  border-bottom: none;
}

.divVideoCont ul li:hover {
  background-color: #5aaaff;
  color: #ffffff;
}

.divVideoCont ul li .aItem span {
  font-family: 'OswaldLight';
  font-size: 14px;
  margin-left: 10px;
}

.divVideoCont ul li .aItem .faxIcon {
  position: absolute;
  top: 11px;
  left: 5px;
  display: none;
}

.divVideoCont ul li .aItemAct {
  background-color: #007bff;
  color: #ffffff;
  border-bottom: none;
}

.divVideoCont ul li .aItemAct .faxIcon {
  display: block;
}

.bodyImgCont .mailbox-attachment-icon {
  min-height: 165px;
}

.bodyFileCont .mailbox-attachments li,
.bodyImgCont .mailbox-attachments li {
  width: 250px;
}

.bodyImgCont .mailbox-attachment-icon {
  height: 198px;
}

.bodyFileCont .mailbox-attachment-size .spFont,
.bodyImgCont .mailbox-attachment-size .spFont {
  font-size: 12px;
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle {
}

.tabListCont .divNavTitle .divTitleCont {
  padding: 10px 0px 10px 10px;
  color: #dee2e6;
  cursor: pointer;
  font-size: 18px;
}

.tabListCont .divNavTitle .divTitleCont .spNum {
  background-color: #dee2e6;
  font-family: 'OswaldLight';
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #ffffff;
}

.tabListCont .divNavTitle .divTitleAct {
  color: #3e3e3e;
}

.tabListCont .divNavTitle .divTitleAct .spNum {
  background-color: #17a2b8;
  color: #ffffff;
}

.tabListCont .divNavTitle .nav-link {
  font-size: 14px;
  color: #b1b1b1;
}

.tabListCont .divNavTitle .nav-link .spNum {
  font-family: 'OswaldLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
}

.tabListCont .divNavTitle .active {
  color: #007bff;
}

.DetailsPro .divRoleTitle {
  font-size: 18px;
  color: #323e56;
  padding-top: 20px;
}

.DetailsPro .divRoleTitle .spItem {
  float: right;
  margin-left: 5px;
  font-size: 14px;
  color: #28a745;
}

.DetailsPro .divRoleTitle .spItem em {
  font-style: normal;
  background-color: #28a745;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'OswaldLight';
}

.DetailsPro .divRoleTitle .el-button {
  padding: 0;
  border: none;
}

.DetailsPro .divRoleTitle .el-button:hover {
  background-color: transparent;
}

.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  padding-left: 70px;
  margin: 15px;
}

.stuInfo .stuFraction {
  position: absolute;
  top: -5px;
  bottom: 0;
  right: -5px;
  font-family: 'OswaldLight';
  font-size: 26px;
  color: #28a745;
}

.stuInfo .stuBtnDown {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.stuInfo .stuBtnDown .aView {
  margin-right: 5px;
  margin-left: 5px;
}

.stuInfo .stuBtnDown .aView .faxSize {
  font-size: 12px;
  color: #343a40;
}

.stuInfo .stuBtnDown .aView:hover .faxSize {
  color: #1666b3;
}

.stuInfo .stuImgCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.stuInfo .stuImgCont img {
  width: 100%;
}

.stuInfo .stuName {
  color: #212e48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuRole {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.stuRole .spStatus {
  font-size: 12px;
  color: #b1b1b1;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}

.cardTabs .abtnTabs {
  font-size: 16px;
  color: #b1b1b1;
  margin-left: 15px;
  position: relative;
  display: block;

  float: left;
  text-align: center;
}

.cardTabs .spDivide {
  float: left;
  padding-left: 15px;
}

.cardTabs .abtnTabs:hover {
  color: #323e56;
}

.cardTabs .abtnTabs .spFaxIcon {
  display: none;
}

.cardTabs .abtnTabs:first-child {
  margin-left: 0;
}

.cardTabs .abtnTabs .spNumber {
  background-color: #dee2e6;
  font-size: 12px;
  color: #ffffff;
  font-family: 'OswaldLight';

  padding: 1px 5px;
  border-radius: 5px;
}

.cardTabs .abtnTabAct {
  font-size: 1.1rem;
  color: #323e56;
}

.cardTabs .abtnTabAct .spFaxIcon {
  display: block;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
}

.cardTabs .abtnTabAct .spNumber {
  background-color: #1666b3;
  color: #ffffff;
  font-size: 14px;
}

.infoList {
  padding-bottom: 17px;
  padding-left: 30px;
}

.infoList .spName {
  font-size: 32px;
  color: #212e48;
  padding-right: 30px;
  height: 40px;
}

.infoList .spFonts {
  color: #3b475d;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d6d6d6;
}

.infoList .spFonts:first-child {
  padding-left: 0;
}

.infoList .spFonts:last-child {
  border-right: none;
}

.infoList .spMedal {
  padding-right: 15px;
}

.infoList .spFonts .faxSize {
  font-size: 18px;
}

.infoList .spFonts .icon {
  color: #ffffff;
}

.infoList .spStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spNStatus {
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
}

.infoList .spFonts .spLabel {
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #b1b1b1;
  color: #ffffff;
  margin-right: 10px;
}

.infoList .spFonts .spNum {
  padding-left: 10px;
  padding-right: 5px;
  font-family: 'OswaldLight';
  font-size: 16px;
  font-weight: bold;
  color: #dc3545;
}

.DetailsPro .colRemarksCont {
  font-size: 14px;
  letter-spacing: 1px;
}

.divNullDataBox {
  height: 35px;
  color: #9e9e9e;
}
</style>
