<!--
  班主任手册-双优生分析
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <div ref="divSearch">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px">搜索</h3>
        </div>
        <div class="card-body">
          <el-row :gutter="20">
            <el-col :lg="6" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">学期</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.SemesterId" filterable placeholder="请选择学期" class="width_100Pie" @change="onChangeSemester">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">月份</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.Month" filterable clearable placeholder="请选择" class="width_100Pie" @change="onChangeMonth">
                  <el-option v-for="(item, index) in optMonth" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">校历周</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.WeekIndex" filterable clearable placeholder="请选择" class="width_100Pie" @change="onChangeWeekIndex">
                  <el-option v-for="item in optWeek" :key="item" :label="`第${item}周`" :value="item"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">班级</span>
              </div>
              <div class="selBodyBox">
                <el-cascader
                  v-if="!isTeacher"
                  v-model="searchForm.ClassId"
                  :options="optClass"
                  filterable
                  clearable
                  placeholder="请选择班级"
                  :props="{ expandTrigger: 'hover' }"
                  class="width_100Pie"
                ></el-cascader>
                <el-select v-else v-model="searchForm.ClassId" filterable placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="card-footer text-center">
          <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
          <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
        </div>
      </el-card>
    </div>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">全校综合积分统计</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" v-if="isPower.admin_statistics_moral_export">
              <el-button @click="onExportData()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <el-table :data="itemData" stripe class="elTableData" :height="tableHeight">
          <el-table-column prop="rank" label="排名"></el-table-column>
          <el-table-column prop="studentNo" label="学号"></el-table-column>
          <el-table-column prop="studentName" label="姓名"></el-table-column>
          <el-table-column prop="score" label="总分" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'; //moment.js
import '@/assets/css/Admin.css';
export default {
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      itemData: [], //列表数据
      // 学期
      optSemester: [],
      // 月份
      optMonth: [],
      // 校历周
      optWeek: [],
      // 班级
      optClass: [],
      searchForm: {
        SemesterId: null,
        Month: null,
        WeekIndex: null,
        ClassId: null
      },
      searchFormReset: null,
      isPower: {
        admin_statistics_moral_export: false,
        admin_statistics_moral_index: false
      },
      isTeacher: false,
      currentDay: moment(new Date()).format('YYYY-MM-DD'),
      tableHeight: 300
    };
  },
  created() {
    let that = this;
    isPower(that, 'admin_statistics_moral_export', 'admin_statistics_moral_export');
    isPower(that, 'admin_statistics_moral_index', 'admin_statistics_moral_index');

    // 获取教师信息
    let tpUserInfo = onGetNowUser(that);
    // 判断是否为班主任
    that.isTeacher = tpUserInfo.isTeacher;

    //获取班级
    ajaxGet(that, '/api/admin/college/linkage', null, (resLinkage) => {
      //获取学期
      ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
        if (that.isTeacher) {
          //班主任班级集合
          let teacher = tpUserInfo;
          that.optClass = tpUserInfo.manageClass;
          that.searchForm.ClassId = tpUserInfo.manageClass[0].classId;
        } else {
          //管理员年级-班级联动集合
          if (resLinkage && resLinkage.length > 0) {
            that.optClass = resLinkage;
            that.optClass.map((item) => {
              item.value = item.collegeId;
              item.label = item.name;
              item.children = item.classes;
              if (item.children && item.children.length > 0) {
                item.children.map((item2) => {
                  item2.value = item2.classId;
                  item2.label = item2.alias;
                });
              }
            });
          }
        }

        if (resSemester && resSemester.length > 0) {
          that.optSemester = resSemester;
          // 获取当前学期
          let currentSemester = resSemester.find((val) => val.isCurrent == true).semesterId;
          if (!regNull(currentSemester)) {
            that.searchForm.SemesterId = currentSemester;
          } else {
            that.searchForm.SemesterId = resSemester[0].semesterId;
          }
          if (!regNull(that.searchForm.SemesterId)) {
            // 获取当前学期的所有月份
            ajaxGet(that, '/api/admin/semesterday/search', { SemesterId: that.searchForm.SemesterId }, function (resDay) {
              if (resDay && resDay.length > 0) {
                resDay.forEach((element) => {
                  let monthVal = moment(element.day).format('YYYY-MM');
                  if (that.optMonth.indexOf(monthVal) == -1) {
                    that.optMonth.push(monthVal);
                  }

                  let dayVal = moment(element.day).format('YYYY-MM-DD');
                  if (dayVal == that.currentDay) {
                    that.searchForm.WeekIndex = element.weekIndex;
                  }
                });
              }
              that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
              // 获取统计数据
              onGetData(that);
            });
            // 获取当前学期的所有周
            ajaxGet(that, '/api/admin/semesterday/week/' + that.searchForm.SemesterId, null, function (resWeek) {
              if (resWeek) {
                for (let i = 1; i <= resWeek; i++) {
                  that.optWeek.push(i);
                }
              }
            });
          }
        }
      });
    });
  },
  mounted() {
    setTimeout(() => {
      this.tableHeight = onGetTableHeight(this);
    }, 500);
  },
  methods: {
    /**
     * @description 导出
     */
    onExportData() {
      let that = this;
      let data = {
        WeekIndex: that.searchForm.WeekIndex,
        ClassId: null,
        SemesterId: that.searchForm.SemesterId,
        Month: that.searchForm.Month
      };
      if (that.isTeacher) {
        data.ClassId = that.searchForm.ClassId;
      } else {
        data.ClassId = !regNullArray(that.searchForm.ClassId) ? that.searchForm.ClassId[1] : null;
      }
      let fileName = '综合积分统计' + getTimeID() + '.xls';

      that.fullscreenLoading = true;
      ajaxExport(that, '/api/admin/statistics/exportclasscredit', data, fileName, function (res) {
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 学期改变事件
     */
    onChangeSemester() {
      let that = this;
      if (!regNull(that.searchForm.SemesterId)) {
        // 获取当前学期的所有月份
        that.optMonth = [];
        that.searchForm.Month = null;
        ajaxGet(that, '/api/admin/semesterday/search', { SemesterId: that.searchForm.SemesterId }, function (resDay) {
          if (resDay && resDay.length > 0) {
            resDay.forEach((element) => {
              let monthVal = moment(element.day).format('YYYY-MM');
              if (that.optMonth.indexOf(monthVal) == -1) {
                that.optMonth.push(monthVal);
              }

              let dayVal = moment(element.day).format('YYYY-MM-DD');
              if (dayVal == that.currentDay) {
                that.searchForm.WeekIndex = element.weekIndex;
              }
            });
          }
        });
        // 获取当前学期的所有周
        that.optWeek = [];
        that.searchForm.WeekIndex = null;
        ajaxGet(that, '/api/admin/semesterday/week/' + that.searchForm.SemesterId, null, function (resWeek) {
          if (resWeek) {
            for (let i = 1; i <= resWeek; i++) {
              that.optWeek.push(i);
            }
          }
        });
      }
    },
    /**
     * @description 月份改变事件
     * @param {any} val
     */
    onChangeMonth(val) {
      let that = this;
      if (!regNull(val)) {
        that.searchForm.WeekIndex = null;
      }
    },
    /**
     * @description 周改变事件
     * @param {any} val
     */
    onChangeWeekIndex(val) {
      let that = this;
      if (!regNull(val)) {
        that.searchForm.Month = null;
      }
    },
    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      onGetData(that);
    },
    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      onGetData(that);
    }
  },
  watch: {
    $route() {
      let that = this;
      onGetData(that);
    }
  }
};

function onGetData(that) {
  let data = {
    WeekIndex: that.searchForm.WeekIndex,
    ClassId: null,
    SemesterId: that.searchForm.SemesterId,
    Month: that.searchForm.Month
  };
  if (that.isTeacher) {
    data.ClassId = that.searchForm.ClassId;
  } else {
    data.ClassId = !regNullArray(that.searchForm.ClassId) ? that.searchForm.ClassId[1] : null;
  }
  ajaxGet(that, '/api/admin/statistics/classcredit', data, function (res) {
    that.itemData = res;
  });
}
</script>

<style scoped="scoped" lang="less">
.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.divBtnCont {
  text-align: right;
  padding-bottom: 0.9375rem;
  /*15px*/
}
</style>

<style lang="less">
.elTableData {
  .tdClass {
    background-color: #edf5ff !important;
  }

  .tdDormitory {
    background-color: #f0f9eb !important;
  }
}
</style>
