<template>
  <div class="top-middle-cmp">
    <!-- 250px -->
    <div :id="comID" class="echartsBody"></div>
  </div>
</template>

<script>
// 按需引入
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/grid';

import * as echarts from 'echarts/lib/echarts';
import { GridComponent } from 'echarts/components';
echarts.use([GridComponent]);

// 引入柱状图组件
require('echarts/lib/chart/line');
export default {
  name: 'TopMiddleCmp',
  props: {
    /**
     * @description 数据
     */
    comChartData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      comID: 'myChartLine',
      pieStyle: '',
      myChart: {},
      chartData: [],
      legendData: [],
      xAxisData: []
    };
  },
  created() {
    let that = this;
  },
  mounted() {
    let that = this;
    // window.onresize = function() {
    //   if (!regNull(that.myChart)) {
    //     that.myChart.resize();
    //   }
    //   that.pieStyle = 'width: 100%; height:' + (520 * 0.0625) + 'rem;';
    // }
  },
  methods: {
    /**
     * @description 调用方法
     * @param {Object} objData
     */
    onGetRadar(objData) {
      let that = this;
      that.chartData = [];
      that.xAxisData = [];
      that.legendData = [];

      if (!regNull(objData)) {
        if (!regNullArray(objData.time)) {
          that.xAxisData = objData.time;
        }
        if (!regNullArray(objData.seriesData)) {
          objData.seriesData.forEach((item) => {
            that.legendData.push(item.configName);
            that.chartData.push({
              name: item.configName,
              type: 'line',
              smooth: true,
              symbolSize: 0,
              data: item.score
            });
          });
        }

        that.$nextTick(() => {
          that.drawLine();
        });
      }
    },
    /**
     * @description 生成雷达图
     */
    drawLine() {
      let that = this;
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById(that.comID));

      if (!regNullArray(that.chartData) && !regNullArray(that.xAxisData) && !regNullArray(that.legendData)) {
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            y: 'top',
            data: that.legendData,
            textStyle: {
              fontSize: 12, //字体大小
              color: '#b9b8ce' //字体颜色
            }
          },
          xAxis: {
            data: that.xAxisData,
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#b9b8ce'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#b9b8ce'
              }
            },
            splitLine: {
              show: false
            }
            // min: 110,
            // max: 135,
            // interval: 5
          },
          color: ['#4992ff', '#7cffb2', '#fbdd60', '#f56e76', '#58d9f9', '#40c090', '#f68a46'],
          series: that.chartData
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.echartsBody {
  width: 100%;
  height: 520px;
}

@media screen and (max-width: 1601px) {
  .echartsBody {
    height: 432px;
  }
}

@media screen and (max-width: 1441px) {
  .echartsBody {
    height: 389px;
  }
}

@media screen and (max-width: 1366px) {
  .echartsBody {
    height: 370px;
  }
}

@media screen and (max-width: 1280px) {
  .echartsBody {
    height: 340px;
  }
}
</style>
