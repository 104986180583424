<!--
  统计分析-行为规范统计
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <div ref="divSearch">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="width: 100%; padding-left: 0px; flex: 1" v-if="SearchTitles != null && SearchTitles.length > 0">
            <ul class="nav nav-pills navTabsCont">
              <li class="nav-item" v-for="(p, index) in SearchTitles" :key="index">
                <router-link :to="p.url" :class="p.isCurrent == isCurrentType ? 'nav-link active' : 'nav-link'">
                  {{ p.name }}
                </router-link>
              </li>
            </ul>
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="20">
            <el-col :lg="6" :sm="12" class="mb-3" v-if="isCurrentType == 1">
              <div class="selTitleBox">
                <span class="spTitle">年级</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.CollegeId" filterable placeholder="请选择年级" class="width_100Pie">
                  <el-option v-for="item in optCollege" :key="item.collegeId" :label="item.name" :value="item.collegeId"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3" v-if="isCurrentType == 2">
              <div class="selTitleBox">
                <span class="spTitle">宿舍楼</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="searchForm.BuildingId" filterable placeholder="请选择宿舍楼" class="width_100Pie">
                  <el-option v-for="item in optBulid" :key="item.buildingId" :label="item.name" :value="item.buildingId"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3" v-if="isCurrentType == 3">
              <div class="selTitleBox">
                <span class="spTitle">班级</span>
              </div>
              <div class="selBodyBox">
                <el-cascader
                  v-model="searchForm.ClassId"
                  :options="optClass"
                  filterable
                  clearable
                  placeholder="请选择班级"
                  :props="{ expandTrigger: 'hover' }"
                  class="width_100Pie"
                ></el-cascader>
              </div>
            </el-col>
            <el-col :lg="6" :sm="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">时间</span>
              </div>
              <div class="selBodyBox">
                <el-date-picker
                  :picker-options="pickerOptions"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="searchForm.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="card-footer text-center">
          <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
          <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
        </div>
      </el-card>
    </div>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">行为规范统计</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" v-if="isPower.admin_statistics_behavior_export">
              <el-button @click="onExportData()" icon="el-icon-download" style="background-color: #17a2b8; color: white">导出</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body" v-if="isCurrentType == 1">
        <div v-if="!regNullArray(itemData.items)">
          <el-table :data="itemData.items" @sort-change="sortChange" stripe class="elTableData" :height="tableHeight">
            <el-table-column prop="rank" label="排名" width="100"></el-table-column>
            <el-table-column prop="name" label="班级" min-width="140"></el-table-column>
            <el-table-column prop="teacher" label="班主任" min-width="140" v-if="isCurrentType == 1"></el-table-column>
            <el-table-column :prop="p" :sortable="isSort ? 'custom' : false" class-name="tdClass" v-for="(p, index) in itemData.titles" :key="index" :label="p">
              <template slot-scope="scope">
                {{ scope.row.scoreList[index] }}
              </template>
            </el-table-column>
            <el-table-column prop="score" label="总分" align="center"></el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-empty :image-size="100" description="暂无数据" name="3"></el-empty>
        </div>
      </div>
      <div class="card-body" v-if="isCurrentType == 2">
        <div v-if="!regNullArray(itemData)">
          <el-row :gutter="24" type="flex" style="flex-wrap: wrap">
            <el-col :span="8" v-for="(it, index) in itemData" :key="index" style="height: 100%">
              <el-table :data="it.items" stripe class="elTableData">
                <el-table-column :label="it.floor" align="center">
                  <el-table-column prop="name" :label="it.floor" width="120" align="center"></el-table-column>
                  <el-table-column prop="class" label="班级" min-width="130" align="center"></el-table-column>
                  <el-table-column prop="score" label="分数" align="center"></el-table-column>
                  <el-table-column prop="rank" label="排名" align="center"></el-table-column>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-empty :image-size="100" description="暂无数据" name="3"></el-empty>
        </div>
      </div>
      <div class="card-body" v-if="isCurrentType == 3">
        <div v-if="!regNullArray(itemData.items)">
          <el-table @sort-change="sortChange" :data="itemData.items" stripe class="elTableData" :height="tableHeight">
            <el-table-column prop="rank" label="排名" width="100"></el-table-column>
            <el-table-column prop="name" label="姓名" min-width="140"></el-table-column>
            <el-table-column prop="class" label="班级" min-width="140"></el-table-column>
            <el-table-column prop="teacher" label="班主任" min-width="140" v-if="isCurrentType == 1"></el-table-column>
            <el-table-column :prop="p" sortable="custom" class-name="tdClass" v-for="(p, index) in itemData.titles" :key="index" :label="p">
              <template slot-scope="scope">
                {{ scope.row.scoreList[index] }}
              </template>
            </el-table-column>
            <el-table-column prop="score" label="总分" align="center"></el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-empty :image-size="100" description="暂无数据" name="3"></el-empty>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'; //moment.js
import '@/assets/css/Admin.css';
import getMonthBounds from '@/plugins/getMonthBounds.js';
// import comChartTree from '@/components/Statistics/ChartTree.vue';
export default {
  components: {
    // comChartTree
  },
  data() {
    return {
      isSort: true,
      fullscreenLoading: false,
      itemData: [], //列表数据
      // 学期
      optSemester: [],
      // 校历周
      optWeek: [],
      // 宿舍楼
      optBulid: [],
      //班级
      optClass: [],
      // 年级
      optCollege: [],
      optMonth: [],
      searchForm: {
        SemesterId: null,
        WeekIndex: null,
        CollegeId: null,
        BuildingId: null,
        ClassId: null,
        Month: null,
        date: null
      },
      SearchTitles: [
        {
          name: '班级',
          url: '/Admin/Statistics/Behavior?isCurrentType=' + 1,
          exportUrl: '/api/admin/moralclass/exportstatistics',
          Api: '/api/admin/moralclass/statistics',
          isCurrent: 1
        },
        {
          name: '宿舍',
          url: '/Admin/Statistics/Behavior?isCurrentType=' + 2,
          exportUrl: '/api/admin/moraldorm/exportstatistics',
          Api: '/api/admin/moraldorm/statistics',
          isCurrent: 2
        },
        {
          name: '个人',
          url: '/Admin/Statistics/Behavior?isCurrentType=' + 3,
          exportUrl: '/api/admin/moralperson/exportstatistics',
          Api: '/api/admin/moralperson/statistics',
          isCurrent: 3
        }
      ],
      isCurrentType: 1,
      isPower: {
        admin_statistics_behavior_export: false // 教师导出
      },
      TeacherInfo: {},
      currentDay: moment(new Date()).format('YYYY-MM-DD'),
      semesterInfo: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '本周',
            onClick: (picker) => {
              picker.$emit('pick', getWeekStartEnd());
            }
          },
          {
            text: '本月',
            onClick: (picker) => {
              picker.$emit('pick', getMonthBounds());
            }
          }
        ]
      },
      tableHeight: 300
    };
  },
  created() {
    let that = this;

    isPower(that, 'admin_statistics_behavior_export', 'admin_statistics_behavior_export');
    that.TeacherInfo = onGetNowUser(that);
    let today = new Date();
    let sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    that.searchForm.date = [sevenDaysAgo, today];
    // this.getCurrDemester()
    ajaxGet(that, '/api/admin/college/linkage', null, (resLinkage) => {
      ajaxGet(that, '/api/admin/college', null, (resCollege) => {
        ajaxGet(that, '/api/admin/building/enable', null, function (resBulid) {
          that.optBulid = resBulid;
          //默认选中宿舍楼第一个
          that.searchForm.BuildingId = that.optBulid[0].buildingId;

          that.optClass = resLinkage;
          that.optClass.map((item) => {
            item.value = item.collegeId;
            item.label = item.name;
            item.children = item.classes;
            if (item.children && item.children.length > 0) {
              item.children.map((item2) => {
                item2.value = item2.classId;
                item2.label = item2.alias;
              });
            }
          });
          that.searchForm.ClassId = [that.optClass[0].value, that.optClass[0].children[0].value];
          that.optClass.unshift({
            value: '',
            label: '全校'
          });

          if (resCollege && resCollege.length > 0) {
            resCollege.unshift({
              collegeId: '',
              name: '全校'
            });
            that.optCollege = resCollege;
            that.optCollege.forEach((item) => {
              item.value = item.collegeId;
              item.label = item.name;
            });
            //默认选中班级第一个
            that.searchForm.CollegeId = that.optCollege[1].collegeId;
          }

          that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
          // 获取统计数据
          onGetData(that);
        });
      });
    });
  },
  mounted() {
    setTimeout(() => {
      this.tableHeight = onGetTableHeight(this);
    }, 500);
  },
  methods: {
    /**
     *
     * @description 表格排序
     */
    sortChange({ column, prop, order }) {
      if (!order) return;
      if (!Array.isArray(this.itemData.items)) {
        return;
      }
      const index = this.itemData.titles.findIndex((item) => item === prop);
      if (order === 'ascending') {
        this.itemData.items.sort((a, b) => {
          return a.scoreList[index] - b.scoreList[index];
        });
      }
      if (order === 'descending') {
        this.itemData.items.sort((a, b) => {
          return b.scoreList[index] - a.scoreList[index];
        });
      }
    },
    /**
     * @description 获取当前学期
     */
    getCurrDemester() {
      ajaxGet(this, '/api/admin/semester', null, (res) => {
        if (!Array.isArray(res)) {
          return;
        }
        const semester = res.find((v) => v.isCurrent);
        if (!semester) return;
        this.semesterInfo = semester;
      });
    },
    /**
     * @description 导出
     */
    onExportData() {
      let that = this;
      let data = {
        CollegeId: that.searchForm.CollegeId,
        BuildingId: that.searchForm.BuildingId,
        ClassId: regNullArray(that.searchForm.ClassId) ? null : that.searchForm.ClassId[that.searchForm.ClassId.length - 1]
      };
      if (!regNullArray(that.searchForm.date)) {
        data.startDate = timeTransform(that.searchForm.date[0]);
        data.endDate = timeTransform(that.searchForm.date[1]);
      }
      let fileName = '行为规范统计' + getTimeID() + '.xls';

      that.fullscreenLoading = true;
      ajaxExport(that, that.SearchTitles[that.isCurrentType - 1].exportUrl, data, fileName, function (res) {
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 学期改变事件
     */
    onChangeSemester() {
      let that = this;
      if (!regNull(that.searchForm.SemesterId)) {
        // 获取当前学期的所有月份
        that.optMonth = [];
        that.searchForm.Month = null;
        ajaxGet(
          that,
          '/api/admin/semesterday/search',
          {
            SemesterId: that.searchForm.SemesterId
          },
          function (resDay) {
            if (resDay && resDay.length > 0) {
              resDay.forEach((element) => {
                let monthVal = moment(element.day).format('YYYY-MM');
                if (that.optMonth.indexOf(monthVal) == -1) {
                  that.optMonth.push(monthVal);
                }

                let dayVal = moment(element.day).format('YYYY-MM-DD');
                if (dayVal == that.currentDay) {
                  that.searchForm.WeekIndex = element.weekIndex;
                }
              });
            }
          }
        );
        // 获取当前学期的所有周
        that.optWeek = [];
        that.searchForm.WeekIndex = null;
        ajaxGet(that, '/api/admin/semesterday/week/' + that.searchForm.SemesterId, null, function (resWeek) {
          if (resWeek) {
            for (let i = 1; i <= resWeek; i++) {
              that.optWeek.push(i);
            }
          }
        });
      }
    },
    /**
     * @description 月份改变事件
     * @param {any} val
     */
    onChangeMonth(val) {
      let that = this;
      if (!regNull(val)) {
        that.searchForm.WeekIndex = null;
      }
    },
    /**
     * @description 周改变事件
     * @param {any} val
     */
    onChangeWeekIndex(val) {
      let that = this;
      if (!regNull(val)) {
        that.searchForm.Month = null;
      }
    },
    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      onGetData(that);
    },
    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      onGetData(that);
    }
  },
  watch: {
    $route() {
      let that = this;
      onGetData(that);
    }
  }
};
//获取本周的开始结束时间 开始周日 结束周六
function getWeekStartEnd() {
  let now = new Date(); // 当前日期
  let nowDayOfWeek = now.getDay() - 1; // 获取今天是本周的第几天，通过减1将周一改为本周的第一天
  let nowDay = now.getDate(); // 当前日
  let nowMonth = now.getMonth(); // 当前月
  let nowYear = now.getFullYear(); // 当前年
  const weekStart = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 1, '00', '00', '00');
  const weekEnd = new Date(nowYear, nowMonth, nowDay + (5 - nowDayOfWeek), 23, 59, 59);
  return [weekStart, weekEnd];
}

function onGetData(that) {
  if (!regNull(that.$route.query.isCurrentType)) {
    that.isCurrentType = that.$route.query.isCurrentType;
  } else {
    that.isCurrentType = 1;
  }

  let data = {};

  if (!regNullArray(that.searchForm.date)) {
    data.startDate = timeTransform(that.searchForm.date[0]);
    data.endDate = timeTransform(that.searchForm.date[1]);
  }

  if (that.isCurrentType == 1) {
    data.CollegeId = that.searchForm.CollegeId;
  } else if (that.isCurrentType == 2) {
    data.BuildingId = that.searchForm.BuildingId;
  } else if (that.isCurrentType == 3) {
    if (that.searchForm.ClassId[0] && that.searchForm.ClassId[1]) {
      data.CollegeId = that.searchForm.ClassId[0];
      data.ClassId = that.searchForm.ClassId[1];
    }
  }

  that.fullscreenLoading = true;

  if (that.searchForm.CollegeId) {
    that.isSort = true;
  } else {
    that.isSort = false;
  }
  if (that.isCurrentType == 3) {
    ajaxPutToGet(that, that.SearchTitles[that.isCurrentType - 1].Api, data, function (res) {
      that.fullscreenLoading = false;
      that.itemData = res || [];
    });
  } else {
    ajaxGet(that, that.SearchTitles[that.isCurrentType - 1].Api, data, function (res) {
      that.fullscreenLoading = false;
      that.itemData = res || [];
    });
  }
}
</script>

<style scoped="scoped" lang="less">
.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.divBtnCont {
  text-align: right;
  padding-bottom: 0.9375rem;
  /*15px*/
}
</style>

<style lang="less">
.elTableData {
  .tdClass {
    background-color: #edf5ff !important;
  }

  .tdDormitory {
    background-color: #f0f9eb !important;
  }
}

.navTabsCont .nav-link {
  font-size: 12px;
}
</style>
