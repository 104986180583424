<!--
  班主任手册-双优生分析
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">搜索</h3>
      </div>
      <div class="card-body">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.SemesterId" filterable placeholder="请选择学期" class="width_100Pie">
                <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.CollegeId" filterable clearable placeholder="请选择年级" class="width_100Pie">
                <el-option v-for="item in optCollege" :key="item.collegeId" :label="item.name" :value="item.collegeId"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <el-row :gutter="24">
      <el-col>
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont" style="padding-left: 0px">获奖级别分布</h3>
          </div>
          <div class="card-body">
            <comChartBar :comID="'myChartBar'" :comHeight="500" ref="myChartBar" :key="ChartBarKey"></comChartBar>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-card class="box-card" v-if="!regNullArray(itemData)">
      <div class="card-body">
        <div v-if="tabsIndex == 0">
          <div v-if="!regNullArray(itemData)">
            <el-table :data="itemData" stripe class="elTableData" border :key="Math.random()" :height="tableHeight">
              <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
              <el-table-column prop="className" label="班级" min-width="140"></el-table-column>
              <el-table-column min-width="100" align="center" v-for="(p, index) in optType" :key="index" :label="p">
                <template slot-scope="scope">
                  {{ scope.row.typeQuantity[index] }}
                </template>
              </el-table-column>
              <el-table-column prop="total" label="总获奖数" align="center" width="120"></el-table-column>
            </el-table>
          </div>
          <div v-else>
            <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
          </div>
        </div>
        <div v-else-if="tabsIndex == 1">
          <div v-if="!regNullArray(itemData)">
            <el-table :data="itemData" stripe class="elTableData" border :key="Math.random()" :height="tableHeight">
              <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
              <el-table-column prop="className" label="班级" min-width="140"></el-table-column>
              <el-table-column min-width="100" align="center" v-for="(p, index) in optLevel" :key="index" :label="p">
                <template slot-scope="scope">
                  {{ scope.row.levelQuantity[index] }}
                </template>
              </el-table-column>
              <el-table-column prop="total" label="总获奖数" align="center" width="120"></el-table-column>
            </el-table>
          </div>
          <div v-else>
            <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'; //moment.js
import '@/assets/css/Admin.css';
import comChartBar from '@/components/Statistics/ChartBar.vue';
// import comChartPie from '@/components/Statistics/ChartPie.vue';
export default {
  components: {
    comChartBar
    // comChartPie
  },
  data() {
    return {
      fullscreenLoading: false,
      itemData: [], //列表数据
      // 学期
      optSemester: [],
      // 年级
      optCollege: [],
      searchForm: {
        SemesterId: null,
        CollegeId: null,
        Month: null
      },

      isPower: {},

      tabslist: [
        {
          name: '奖励类型概况',
          value: '0'
        },
        {
          name: '奖励级别概况',
          value: '1'
        }
      ],
      tabsIndex: 1,
      ChartBarKey: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
      ChartPieKey: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,

      optType: [],
      optLevel: [],
      tableHeight: 400
    };
  },
  created() {
    let that = this;

    //获取年级
    ajaxGet(that, '/api/admin/college', null, (resCollege) => {
      //获取学期
      ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
        if (resCollege && resCollege.length > 0) {
          that.optCollege = resCollege;
          that.optCollege.map((item) => {
            item.value = item.collegeId;
            item.label = item.name;
          });
        }

        if (!regNullArray(resSemester)) {
          that.optSemester = resSemester;
          // 获取当前学期
          let currentSemester = resSemester.find((val) => val.isCurrent == true).semesterId;
          if (!regNull(currentSemester)) {
            that.searchForm.SemesterId = currentSemester;
          } else {
            that.searchForm.SemesterId = resSemester[0].semesterId;
          }
          onGetData(that);
          that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
        }
      });
    });
  },
  mounted() {
    setTimeout(() => {
      let tpNumber = window.innerHeight - 150;
      this.tableHeight = tpNumber > 400 ? tpNumber : 400;
    }, 500);
  },
  methods: {
    /**
     * @description 切换
     */
    onTabClick() {},
    /**
     * @description 导出
     */
    onExportData() {
      let that = this;
      let data = {
        SemesterId: that.searchForm.SemesterId,
        CollegeId: that.searchForm.CollegeId,
        Month: that.searchForm.Month
      };
      let fileName = '行为规范统计' + getTimeID() + '.xls';

      that.fullscreenLoading = true;
      ajaxExport(that, '/api/admin/statistics/exportclasssummary', data, fileName, function (res) {
        that.fullscreenLoading = false;
      });
    },

    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      onGetData(that);
    },
    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      onGetData(that);
    }
  },
  watch: {
    $route() {
      let that = this;
      onGetData(that);
    }
  }
};

function onGetData(that) {
  let data = {
    SemesterId: that.searchForm.SemesterId,
    CollegeId: that.searchForm.CollegeId
  };
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/admin/statistics/prizesummary', data, function (res) {
    that.fullscreenLoading = false;

    // 获奖级别分布
    if (!regNull(that.$refs.myChartBar)) {
      that.$refs.myChartBar.onGetRadar(res);
    }
    // 获奖类型占比
    if (!regNull(that.$refs.myChartPie)) {
      that.$refs.myChartPie.onGetRadar(res);
    }

    if (!regNull(res)) {
      that.optType = res.typeList;
      that.optLevel = res.levelList;
      that.itemData = res.prizeTable;
    }
  });
}
</script>

<style scoped="scoped" lang="less">
.tabsHeader {
  margin: -18px -20px;
  background-color: #f5f7fa;
  position: relative;

  .divSemesterBox {
    position: absolute;
    right: 6px;
    top: 6px;

    .spTitle {
      font-size: 14px;
    }
  }
}

.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.divBtnCont {
  text-align: right;
  padding-bottom: 0.9375rem;
  /*15px*/
}
</style>

<style lang="less">
.tabsHeader {
  .el-tabs__header {
    margin: 0;
    margin-bottom: -1px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #ffffff;
  }
}

.elTableData {
  .tdClass {
    background-color: #edf5ff !important;
  }

  .tdDormitory {
    background-color: #f0f9eb !important;
  }
}
</style>
