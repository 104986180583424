<!--
  班主任手册-双优生分析
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <!--<div slot="header" class="card-header tabsHeader">

         <el-tabs v-model="tabsIndex" type="card" @tab-click="onTabClick">
          <el-tab-pane v-for="(item,index) in tabslist" :key="index" :label="item.name" :name="item.value">
          </el-tab-pane>
        </el-tabs>
      </div> -->
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">评价类型溯源</h3>
      </div>
      <div class="card-body" style="min-height: 200px">
        <el-alert title="点击节点可以进行伸缩" type="warning" show-icon :closable="false"></el-alert>
        <br />
        <comChartTree :comHeight="echartHeight" ref="comChartTree" :key="echartKey"></comChartTree>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'; //moment.js
import '@/assets/css/Admin.css';
import comChartTree from '@/components/Statistics/ChartTree.vue';
export default {
  components: {
    comChartTree
  },
  data() {
    return {
      fullscreenLoading: false,
      itemData: [], //列表数据
      tabslist: [
        {
          name: '学生综合',
          value: '1'
        },
        {
          name: '班级综合',
          value: '2'
        }
      ],
      tabsIndex: '1',
      echartHeight: 0,
      echartKey: Math.random()
    };
  },
  created() {
    let that = this;
    that.onGetData();
  },
  methods: {
    /**
     * @description 加载
     */
    onGetData() {
      let that = this;
      that.fullscreenLoading = true;
      let tempData = [
        {
          id: '00000000-0000',
          parentId: null,
          name: setInt(that.tabsIndex) == 1 ? '学生综合' : '班级综合',
          type: 0
        }
      ];
      that.echartHeight = 0;
      that.echartKey = that.onCreatedCode(10);
      ajaxGet(that, '/api/admin/growthconfig/growthconfigsummary?configType=' + setInt(that.tabsIndex), null, function (resData) {
        // 数据循环处理
        resData.forEach((item) => {
          // 先记录综合数据
          tempData.push({
            id: item.configId,
            parentId: regNull(item.parentId) ? '00000000-0000' : item.parentId,
            name: item.name,
            baseScore: item.baseScore,
            ceiling: item.ceiling,
            remark: item.description,
            type: 1 // 综合类型
          });

          // 将溯源类型数据进行处理，然后记录
          if (!regNullArray(item.applys)) {
            item.applys.forEach((list) => {
              let tpUUID = that.onGetRandomUUID();
              tempData.push({
                id: tpUUID,
                parentId: item.configId,
                name: list.typeName,
                remark: list.remark,
                type: 2
              });

              // 将溯源类型数据的详情数据进行处理，然后记录
              if (!regNullArray(list.items)) {
                list.items.forEach((it) => {
                  that.echartHeight += 25;
                  tempData.push({
                    id: it.id,
                    parentId: tpUUID,
                    name: regNull(it.role) ? it.name : it.name + '【' + it.role + '】',
                    role: it.role,
                    title: it.title,
                    score: it.score,
                    type: 3
                  });
                });
              }
            });
          }
        });

        if (!regNull(that.$refs.comChartTree)) {
          that.$refs.comChartTree.onGetRadar(onGetJsonToTree(tempData, 'id', 'parentId', 'children'), that.echartHeight);
        }
        that.fullscreenLoading = false;
      });
    },
    /**
     * @description 切换
     */
    onTabClick() {
      let that = this;
      that.onGetData();
    },
    /**
     * @description 简易生成随机UUID
     */
    onGetRandomUUID() {
      let that = this;
      return `${that.onCreatedCode(8)}-${that.onCreatedCode(4)}-${that.onCreatedCode(4)}-${that.onCreatedCode(4)}-${that.onCreatedCode(12)}`;
    },
    /**
     * @description 随机数字+字母
     * @param {Object} objNumber
     */
    onCreatedCode(objNumber) {
      let that = this;
      const len = objNumber;
      const codeList = [];
      const chars = 'abcdefhijkmnprstwxyz0123456789';
      const charsLen = chars.length;
      for (let i = 0; i < len; i++) {
        codeList.push(chars.charAt(Math.floor(Math.random() * charsLen)));
      }
      return codeList.join('');
    }
  },
  watch: {}
};
</script>

<style scoped="scoped" lang="less">
.tabsHeader {
  margin: -18px -20px;
  background-color: #f5f7fa;
  position: relative;

  .divSemesterBox {
    position: absolute;
    right: 6px;
    top: 6px;

    .spTitle {
      font-size: 14px;
    }
  }
}
</style>

<style lang="less">
.tabsHeader {
  .el-tabs__header {
    margin: 0;
    margin-bottom: -1px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #ffffff;
  }
}
</style>
