<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">搜索</h3>
      </div>
      <div class="card-body">
        <el-form ref="form" :model="infoForm" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">活动类型</span>
              </div>
              <div class="selBodyBox">
                <el-cascader v-model="infoForm.typeId" class="width_100Pie" :options="infoForm.optType" clearable @change="onChangeType"></el-cascader>
              </div>
            </el-col>
            <el-col :span="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">活动名称</span>
              </div>
              <div class="selBodyBox">
                <el-input v-model="infoForm.name" placeholder="请选择活动名称"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">活动等级</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="infoForm.levelId" placeholder="请选择活动等级" style="width: 100%">
                  <el-option v-for="item in infoForm.optLevel" :key="item.levelId" :label="item.name" :value="item.levelId"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">活动时间</span>
              </div>
              <div class="selBodyBox">
                <el-date-picker
                  style="width: 100%"
                  v-model="infoForm.currentDate"
                  type="daterange"
                  format="yyyy年 MM月dd日"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click="onSearch()">查询</el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">活动信息</h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table :data="itemData" stripe class="elTableData">
          <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
          <el-table-column prop="name" label="名称" v-if="isField.isName == true" min-width="300">
            <template slot-scope="scope">
              <span class="spIcon spBan">{{ scope.row.level }}</span>
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="地点" v-if="isField.isPlace == true" min-width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.place }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="活动时间" min-width="240" v-if="isField.isTime == true">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate | dateformatMinute }} 至 {{ scope.row.endDate | dateformatMinute }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="报名状态" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.arrOperate.statusStuName }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="活动状态" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.arrOperate.statusName }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.activityId)"></operationBtn>
              <operationBtn v-if="scope.row.arrOperate.isBtnUpload" :btnName="'上传文件'" @click.native="onUpload(scope.row.activityId)"></operationBtn>
              <operationBtn v-if="scope.row.arrOperate.isBtnJoin" :btnName="'立即报名'" @click.native="onEnroll(scope.row.activityId)"></operationBtn>
              <operationBtn v-if="scope.row.arrOperate.isBtnCancel" :btnName="'取消报名'" @click.native="onRevokeEnroll(scope.row.activityId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import '@/assets/css/Student.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';

export default {
  components: {
    comPage,
    operationBtn
  },
  data() {
    return {
      // 动画
      fullscreenLoading: false,
      // 搜索
      SearchList: null,
      //列表数据
      itemData: [],
      //分页查询接口返回的结果
      page: null,
      //分页左侧按钮集合
      pageBtnList: null,
      //分页数据
      paging: {
        // 总共页数
        pageLength: 0,
        // 每页请求的数据量
        Size: 10,
        //当前在第几页
        Index: 1
      },
      isField: {
        //字段是否需要显示
        isName: false, //活动名称
        isPlace: false, //活动地点
        isTime: false, //活动时间
        isLevel: false //活动类型
      },
      arrSearch: {
        TypeId: ''
      },
      infoForm: {
        name: '',
        currentDate: '',
        typeId: [],
        levelId: '',
        optType: [],
        optLevel: [
          {
            levelId: 'd2cbb9e7-2f2f-436a-914a-067f4b930b6f',
            name: '校级',
            coefficient: 4
          },
          {
            levelId: 'f20d9f55-9421-4003-90f7-792db883d828',
            name: '部级',
            coefficient: 2
          },
          {
            levelId: '0f458a03-5872-44e5-90b6-1f363eedd7e6  ',
            name: '班级',
            coefficient: 1
          }
        ]
      }
    };
  },
  methods: {
    // 数据处理
    onGetOptions(that, _data) {
      let arrTemp = [];
      if (_data.length != 0) {
        let temp = [];
        for (let item of _data) {
          if (item.children.length != 0) {
            temp.push({
              value: item.id,
              label: item.name,
              children: that.onGetOptions(that, item.children)
            });
          } else {
            temp.push({
              value: item.id,
              label: item.name
            });
          }
        }
        arrTemp = temp;
      }
      return arrTemp;
    },
    // 活动类型
    onChangeType(res) {
      let that = this;
    },
    onResetSearch() {
      let that = this;
      that.infoForm.name = '';
      that.infoForm.currentDate = '';
      that.infoForm.typeId = [];
      that.infoForm.levelId = '';
      that.paging.Index = 1;
      onGetDataPage(that);
    },
    onSearch() { // 搜索
      let that = this;
      that.paging.Index = 1;
      onGetDataPage(that);
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: '/Student/LiteracyActivities/Details/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onUpload(Id) {
      let that = this;
      that.$router.push({
        path: '/Student/LiteracyActivities/Upload/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    /**
     * @description 学生报名活动
     * @param {Object} Id
     */
    onEnroll(Id) {
      let that = this;
      confirmDelete(that, '确定参加本次活动吗？', function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxPost(that, '/api/student/activity/enroll/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            onGetDataPage(that);
          });
        }
      });
    },
    onRevokeEnroll(
      Id //取消活动报名
    ) {
      let that = this;
      confirmRevokeEnroll(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/student/activity/revokeenroll/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            onGetDataPage(that);
          });
        }
      });
    },
    onGetType(that) {
      if (that.infoForm.typeId != null && that.infoForm.typeId.length > 0) {
        return that.infoForm.typeId[that.infoForm.typeId.length - 1];
      }
      return '';
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    // 分页面按钮
    pagingClick(type, val) {
      let that = this;
      if (type == 1) {
        //更改每页条数触发的事件
        that.paging.Size = val;
        that.paging.Index = 1;
      } else {
        //更改当前页时触发的事件
        that.paging.Index = val;
      }
      onGetDataPage(that);
    }
  },
  created() {
    let that = this;
    onGetDataPage(that);
  },
  mounted() {},
  watch: {
    $route() {
      let that = this;
      onGetDataPage(that);
    }
  }
};

function onGetDataPage(that) {
  that.fullscreenLoading = true;

  ajaxGet(that, '/api/student/activity/typetreelist', null, function (resType) {
    //ajaxGet(that,'/api/student/activitylevel',null,function(resLevel){
    //that.infoForm.optLevel = resLevel;

    if (resType != null && resType.length > 0) {
      that.infoForm.optType = that.onGetOptions(that, resType);
    }

    let data = [];
    data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Name: that.infoForm.name,
      TypeId: that.onGetType(that),
      LevelId: that.infoForm.levelId,
      // ActivityDate: that.infoForm.currentDate,
      StartDate: that.infoForm.currentDate[0],
      EndDate: that.infoForm.currentDate[1]
    };

    //默认活动类型为第一个
    // if (data.TypeId == '') {
    //   if (that.infoForm.optType[0].hasOwnProperty('children')) {
    //     data.TypeId = that.infoForm.optType[0].children.value;
    //     that.infoForm.typeId.push(that.infoForm.optType[0].children.value);
    //   } else {
    //     data.TypeId = that.infoForm.optType[0].value;
    //     that.infoForm.typeId.push(that.infoForm.optType[0].value);
    //   }
    // }

    ajaxGet(that, '/api/student/activity/search', data, function (resData) {
      that.page = resData;
      that.itemData = [];
      //rosterStatusInt	 1：待审核 2：审核通过 3：审核不通过 0:未报名
      for (let it of resData.items) {
        that.itemData.push({
          level: it.level,
          name: it.name,
          place: it.place,
          startDate: it.startDate,
          endDate: it.endDate,
          statusString: it.statusString,
          activityId: it.activityId,
          isAllowApply: it.isAllowApply,
          rosterStatusInt: it.rosterStatusInt,
          // 是否为审核人员
          isAdmin: false,
          arrOperate: onGetActivityStatus(
            it.isApply, //是否需要报名
            it.status, //活动当前状态
            it.isImport, //是否导入报名范围
            it.isUpload, //是否上传
            it.applyStartDate, //报名开始时间
            it.applyEndDate, //报名结束时间
            it.startDate, //活动开始时间
            it.endDate, //活动结束时间
            it.uploadDeadline, //学生上传文件截止时间
            false, //是否为审核员
            false, //是否可以代报名
            true, //是否为学生
            it.isAllowApply, //是否可参加活动
            Number(it.rosterStatusInt), //名单状态
            it.isAttendance, //是否需要签到
            it.attended, //学生签到情况
            it.isApplyAudit //是否需要报名审核
          )
        });

        // it.statusString = '';
        // it.isTimeUpload = false;
        // if (it.startDate != null && it.endDate != null) {
        //   if (it.applyStartDate != null && it.applyEndDate != null) {
        //     if (compareDate(it.applyStartDate, it.currentDate) == true && compareDate(it.applyEndDate,
        //         it.currentDate) == false) {
        //       it.statusString = '代报名';
        //     }
        //     if (compareDate(it.applyEndDate, it.currentDate) == true && compareDate(it.startDate, it
        //         .currentDate) == false) {
        //       it.statusString = '确认报名名单';
        //     }
        //   }
        //   else
        //   {
        //     if (compareDate(it.startDate, it
        //         .currentDate) == false) {
        //       it.statusString = '活动未开始';
        //     }
        //   }
        //   if (compareDate(it.startDate, it.currentDate) == true && compareDate(it.endDate, it
        //       .currentDate) == false) {
        //     it.statusString = '进行中';
        //   }
        //   if (compareDate(it.endDate, it.currentDate) == true) {
        //     it.statusString = '活动结束';
        //   }
        // } else {
        //   it.statusString = '活动结束';
        // }
        // if(it.uploadDeadline!='')
        // {
        //   if (compareDate(it.uploadDeadline, it.currentDate) == false) {
        //     it.isTimeUpload = true;
        //   }
        // }
      }

      if (that.itemData.length > 0) {
        that.isField.isName = false;
        that.isField.isPlace = false;
        that.isField.isTime = false;
        that.isField.isLevel = false;
        for (var i in resType) {
          if (resType[i].id == resData.items[0].typeId) {
            for (var field of resType[i].fieldConfigs) {
              if (field.name == 1) {
                that.isField.isName = true;
              } else if (field.name == 2) {
                that.isField.isPlace = true;
              } else if (field.name == 3) {
                that.isField.isLevel = true;
              } else if (field.name == 5) {
                that.isField.isTime = true;
              }
            }
          } else {
            for (var j in resType[i].children) {
              if (resType[i].children[j].id == resData.items[0].typeId) {
                for (var field of resType[i].children[j].fieldConfigs) {
                  if (field.name == 1) {
                    that.isField.isName = true;
                  } else if (field.name == 2) {
                    that.isField.isPlace = true;
                  } else if (field.name == 3) {
                    that.isField.isLevel = true;
                  } else if (field.name == 5) {
                    that.isField.isTime = true;
                  }
                }
              }
            }
          }
        }
      }
      that.fullscreenLoading = false;
    });
    //})
  });
}
// 取消报名确认弹出框
function confirmRevokeEnroll(that, msg, callback) {
  if (msg == null || typeof msg == 'undefined' || msg == '') {
    msg = '您确定要取消报名活动吗？';
  }
  confirm(that, msg, callback);
}
</script>

<style scoped="scoped">
.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.selBodyBox {
  padding-top: 5px;
}

.spIcon {
  background-color: #f8f9fa;
  font-size: 14px;
  padding: 2px 5px;
}

.spBan {
  color: #409eff;
}

.spBu {
  color: #fac10b;
}

.spXiao {
  color: #f6402c;
}

.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}
</style>
