<template>
  <div id="data-view">
    <dv-full-screen-container v-if="isLoading">
      <dv-loading>Loading...</dv-loading>
    </dv-full-screen-container>

    <dv-full-screen-container v-else>
      <!-- 头部标题、时间、学期 -->
      <topHeader :comSemesterName="semesterName"></topHeader>

      <div class="dataBody">
        <!-- 左侧 -->
        <div class="divWebLeft">
          <!-- 总分 -->
          <div class="divTotalBox">
            <dv-border-box-12 class="left-chart-container">
              <Top-Left-Cmp ref="comTopLeftCmp" />
            </dv-border-box-12>
          </div>
          <!-- 学生评价动态 -->
          <div class="divLeftBottom">
            <comEvaluate ref="comEvaluate" />
          </div>
        </div>
        <!-- 中间 -->
        <div class="divWebMid">
          <!-- 统计图 -->
          <div class="midTopBox">
            <TopMiddleCmp ref="TopMiddleCmp" />
          </div>
          <!-- 按素养类型学生排行 -->
          <div class="divMidBottom">
            <comStudentRank ref="comStudentRank" />
          </div>
        </div>
        <!-- 右侧 -->
        <div class="divWebRight">
          <!-- 各专业部班级总分排行 -->
          <comClassRank ref="comClassRank" />
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import TopLeftCmp from './TopLeftCmp';
import TopMiddleCmp from './TopMiddleCmp';
import comClassRank from './ClassRank';
import comEvaluate from './Evaluate';
import comStudentRank from './StudentRankType';
import topHeader from './topHeader';
export default {
  components: {
    topHeader,
    TopLeftCmp,
    TopMiddleCmp,
    comClassRank,
    comEvaluate,
    comStudentRank
  },
  props: {
    comData: {
      type: Boolean,
      default: false
    },
    comMonth: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: true,
      // 学期名称
      semesterName: null,
      // 当前时间
      nowDateTime: '',
      tabTtimer: null,

      itemParams: null,

      windowWidth: 0,
      windowHeight: 0
    };
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.isLoading = false;

      return false;
    }, 1500);
  },
  mounted() {
    let that = this;

    that.windowWidth = window.innerWidth;
    that.windowHeight = window.innerHeight;

    if (that.windowWidth >= 1280) {
      that.tabTtimer = setInterval(() => {
        that.onGetBigScreen();
      }, 1000 * 60 * 10);
    }
  },
  methods: {
    /**
     * @description 加载
     * @param {Object} objData
     */
    onGetBigScreen() {
      let that = this;

      // that.itemParams = objData;
      // that.semesterName = objData.Name + (regNull(that.comMonth) ? '' : ' ' + timeTransform(that.comMonth + '-01', 'MM') + '月');

      ajaxGetNoToken(that, '/api/statis/getbigscreendata/bigscreen', null, function (resData) {
        if (!regNull(resData)) {
          // 总分、类型总分
          if (!regNullArray(resData.configData)) {
            if (!regNull(that.$refs.comTopLeftCmp)) {
              setTimeout(function () {
                that.$refs.comTopLeftCmp.onCreateData({
                  scoreSchool: setTwoDecimal(resData.totalScore, 2),
                  config: resData.configData.map((item) => ({
                    name: item.configName,
                    score: setTwoDecimal(item.score, 2)
                  }))
                });
                return false;
              }, 200);
            }
          }

          // 全校学生素养总分班级排行
          if (!regNull(that.$refs.comClassRank)) {
            that.$refs.comClassRank.onCreateData(resData.collegeData);
          }

          // 折线统计图
          if (!regNull(that.$refs.TopMiddleCmp)) {
            that.$refs.TopMiddleCmp.onGetRadar(resData.chartData);
          }

          // 评价动态
          if (!regNull(that.$refs.comEvaluate)) {
            that.$refs.comEvaluate.onCreateData(resData.logData);
          }

          // 素养类型排行榜
          if (!regNull(that.$refs.comStudentRank)) {
            that.$refs.comStudentRank.onCreateData(resData.configData);
          }
        }
      });
    },

    /**
     * @description 销毁定时
     */
    onClearBigScreen() {
      clearInterval(this.tabTtimer);
    },
    onStopTime() {
      let that = this;
      that.$refs.comStudentRank.onStopTime();
      clearInterval(that.tabTtimer);
    }
  }
};
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .dataBody {
    display: flex;
    height: calc(~'100% - 100px');
  }

  .divWebLeft {
    width: 490px;
    padding-left: 10px;

    .divTotalBox {
      width: 100%;
      height: calc(~'100% - 491px');

      .divTop {
        width: 100%;
        height: 400px;
      }
    }

    .divLeftBottom {
      margin-top: 10px;
      width: 100%;
      height: calc(~'100% - 515px');
    }
  }

  .divWebMid {
    width: 940px;

    .midTopBox {
      width: 100%;
      height: calc(~'100% - 490px');
    }

    .divMidBottom {
      width: 100%;
      height: 480px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .divWebRight {
    height: calc(~'100% - 10px');
    width: 490px;
  }

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }
}

.border {
  border: none !important;
}

@media screen and (max-width: 1601px) {
  //0.83
  #data-view {
    .divWebLeft {
      .divTotalBox {
        width: 100%;
        height: calc(~'100% - 407px');
      }

      .divLeftBottom {
        height: calc(~'100% - 413px');
      }
    }
    .divWebMid {
      width: 940px;

      .midTopBox {
        width: 100%;
        height: calc(~'100% - 407px');
      }

      .divMidBottom {
        width: 100%;
        height: 398px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  //0.75
  #data-view {
    .divWebLeft {
      .divTotalBox {
        height: calc(~'100% - 366px');
      }

      .divLeftBottom {
        height: calc(~'100% - 366px');
      }
    }
    .divWebMid {
      width: 846px;
      .midTopBox {
        height: calc(~'100% - 366px');
      }

      .divMidBottom {
        height: 358px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  //0.71
  #data-view {
    .divWebLeft {
      .divTotalBox {
        height: calc(~'100% - 348px');
      }

      .divLeftBottom {
        height: calc(~'100% - 338px');
      }
    }
    .divWebMid {
      width: 804px;
      .midTopBox {
        height: calc(~'100% - 348px');
      }

      .divMidBottom {
        height: 340px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  //0.67
  #data-view {
    .divWebLeft {
      .divTotalBox {
        height: calc(~'100% - 327px');
      }

      .divLeftBottom {
        height: calc(~'100% - 311px');
      }
    }
    .divWebMid {
      width: 756px;
      .midTopBox {
        height: calc(~'100% - 327px');
      }

      .divMidBottom {
        height: 320px;
      }
    }
  }
}
</style>
